/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudinaryCloudInfo } from './CloudinaryCloudInfo';
import { CloudinaryCloudInfoFromJSON, CloudinaryCloudInfoFromJSONTyped, CloudinaryCloudInfoToJSON } from './CloudinaryCloudInfo';
import type { CloudinaryOrganizationInfoAccount } from './CloudinaryOrganizationInfoAccount';
import {
  CloudinaryOrganizationInfoAccountFromJSON,
  CloudinaryOrganizationInfoAccountFromJSONTyped,
  CloudinaryOrganizationInfoAccountToJSON,
} from './CloudinaryOrganizationInfoAccount';

/**
 *
 * @export
 * @interface CloudinaryOrganizationInfo
 */
export interface CloudinaryOrganizationInfo {
  /**
   *
   * @type {string}
   * @memberof CloudinaryOrganizationInfo
   */
  id: string;
  /**
   *
   * @type {CloudinaryOrganizationInfoAccount}
   * @memberof CloudinaryOrganizationInfo
   */
  account: CloudinaryOrganizationInfoAccount;
  /**
   *
   * @type {Array<CloudinaryCloudInfo>}
   * @memberof CloudinaryOrganizationInfo
   */
  clouds: Array<CloudinaryCloudInfo>;
}

/**
 * Check if a given object implements the CloudinaryOrganizationInfo interface.
 */
export function instanceOfCloudinaryOrganizationInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'account' in value;
  isInstance = isInstance && 'clouds' in value;

  return isInstance;
}

export function CloudinaryOrganizationInfoFromJSON(json: any): CloudinaryOrganizationInfo {
  return CloudinaryOrganizationInfoFromJSONTyped(json, false);
}

export function CloudinaryOrganizationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryOrganizationInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    account: CloudinaryOrganizationInfoAccountFromJSON(json['account']),
    clouds: (json['clouds'] as Array<any>).map(CloudinaryCloudInfoFromJSON),
  };
}

export function CloudinaryOrganizationInfoToJSON(value?: CloudinaryOrganizationInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    account: CloudinaryOrganizationInfoAccountToJSON(value.account),
    clouds: (value.clouds as Array<any>).map(CloudinaryCloudInfoToJSON),
  };
}
