import { Box, Typography, useTheme } from '@mui/material';
import { useRollbar } from '@rollbar/react';
import { useSnackbar } from 'notistack';
import { ResponseError } from 'src/generated/app_server_sdk';

import { useEvent } from 'src/hooks/useEvent';
import appServer from 'src/utils/appServer';
import { useLogger } from 'src/utils/Logger';

//todo move appServer (Endpoints class) implementation here and refactor all references to use useApi() instead
export type Result<T, E> = [E, undefined] | [undefined, T];
export const useApi = () => {
  const theme = useTheme();
  const rollbar = useRollbar();

  const { enqueueSnackbar } = useSnackbar();
  const logger = useLogger();
  async function wrapResult<T, Error>(
    apiCall: () => Promise<T>,
    options: { showNotification?: boolean } = { showNotification: true },
  ): Promise<Result<T, Error>> {
    try {
      const result = await apiCall();
      return [undefined, result];
    } catch (e) {
      const error = await responseErrorParser(e);
      if (error.status) {
        // API error (includes status code)
        rollbar.info('wrapResult API Error', error);
        logger.info(error);
      } else {
        // runtime error
        rollbar.error(error);
        logger.error(error);
      }
      if (options.showNotification) {
        enqueueSnackbar(
          <Box>
            <Typography variant='body1'>{error.message}</Typography>

            <Typography variant='body2' sx={{ color: theme.palette.grey[700], fontSize: '10px' }}>
              Support operation id: {error.operationId}
            </Typography>
          </Box>,
          { variant: 'error', autoHideDuration: 6000 },
        );
      }
      return [e, undefined];
    }
  }

  async function responseErrorParser(e: unknown) {
    if (e instanceof ResponseError) {
      const { status } = e.response;
      let error = await e.response.text();
      let requestId = e.response.headers.get('x-request-id');
      let errMessage = error || e.message;
      try {
        const errObj = JSON.parse(error) as ResponseError;
        errMessage = errObj.message;
      } catch (e) {
        // ignore
      }
      if (status >= 500) {
        errMessage = 'Internal Server Error - Please try again later';
      }
      return {
        message: errMessage,
        status: status,
        operationId: requestId || '',
      };
    }
    return {
      message: 'Unknown error - Please try again later',
      status: 500,
      operationId: '',
    };
  }

  return {
    wrapResult: useEvent(wrapResult),
    responseErrorParser: useEvent(responseErrorParser),
    finaltouch: appServer,
  };
};
