/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Brand, BrandWithAssets, DeletedEntityResponse, FontUploadResponse, ListBrandsResponse } from '../models';
import {
  BrandFromJSON,
  BrandToJSON,
  BrandWithAssetsFromJSON,
  BrandWithAssetsToJSON,
  DeletedEntityResponseFromJSON,
  DeletedEntityResponseToJSON,
  FontUploadResponseFromJSON,
  FontUploadResponseToJSON,
  ListBrandsResponseFromJSON,
  ListBrandsResponseToJSON,
} from '../models';

export interface BrandsApiCreateBrandRequest {
  brand?: Brand;
}

export interface BrandsApiDeleteBrandRequest {
  id: string;
}

export interface BrandsApiFindBrandRequest {
  id: string;
}

export interface BrandsApiListOrganizationBrandsRequest {
  id: string;
}

export interface BrandsApiUpdateBrandRequest {
  id: string;
  brand?: Brand;
}

export interface BrandsApiUploadFontRequest {
  file_name: string;
  brand_id: string;
  font_file: Blob;
}

/**
 *
 */
export class BrandsApi extends runtime.BaseAPI {
  /**
   */
  async createBrandRaw(
    requestParameters: BrandsApiCreateBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BrandWithAssets>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/brands/create`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: BrandToJSON(requestParameters.brand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BrandWithAssetsFromJSON(jsonValue));
  }

  /**
   */
  async createBrand(brand?: Brand, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandWithAssets> {
    const response = await this.createBrandRaw({ brand: brand }, initOverrides);
    return await response.value();
  }

  /**
   */
  async deleteBrandRaw(
    requestParameters: BrandsApiDeleteBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<DeletedEntityResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteBrand.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/brands/delete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DeletedEntityResponseFromJSON(jsonValue));
  }

  /**
   */
  async deleteBrand(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeletedEntityResponse> {
    const response = await this.deleteBrandRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async findBrandRaw(
    requestParameters: BrandsApiFindBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BrandWithAssets>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling findBrand.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/brands/find/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BrandWithAssetsFromJSON(jsonValue));
  }

  /**
   */
  async findBrand(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandWithAssets> {
    const response = await this.findBrandRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async listOrganizationBrandsRaw(
    requestParameters: BrandsApiListOrganizationBrandsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListBrandsResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listOrganizationBrands.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/brands/list/organization/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ListBrandsResponseFromJSON(jsonValue));
  }

  /**
   */
  async listOrganizationBrands(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListBrandsResponse> {
    const response = await this.listOrganizationBrandsRaw({ id: id }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateBrandRaw(
    requestParameters: BrandsApiUpdateBrandRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<BrandWithAssets>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling updateBrand.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/brands/update/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: BrandToJSON(requestParameters.brand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BrandWithAssetsFromJSON(jsonValue));
  }

  /**
   */
  async updateBrand(id: string, brand?: Brand, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandWithAssets> {
    const response = await this.updateBrandRaw({ id: id, brand: brand }, initOverrides);
    return await response.value();
  }

  /**
   */
  async uploadFontRaw(
    requestParameters: BrandsApiUploadFontRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FontUploadResponse>> {
    if (requestParameters.file_name === null || requestParameters.file_name === undefined) {
      throw new runtime.RequiredError('file_name', 'Required parameter requestParameters.file_name was null or undefined when calling uploadFont.');
    }

    if (requestParameters.brand_id === null || requestParameters.brand_id === undefined) {
      throw new runtime.RequiredError('brand_id', 'Required parameter requestParameters.brand_id was null or undefined when calling uploadFont.');
    }

    if (requestParameters.font_file === null || requestParameters.font_file === undefined) {
      throw new runtime.RequiredError('font_file', 'Required parameter requestParameters.font_file was null or undefined when calling uploadFont.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file_name !== undefined) {
      formParams.append('file_name', requestParameters.file_name as any);
    }

    if (requestParameters.brand_id !== undefined) {
      formParams.append('brand_id', requestParameters.brand_id as any);
    }

    if (requestParameters.font_file !== undefined) {
      formParams.append('font_file', requestParameters.font_file as any);
    }

    const response = await this.request(
      {
        path: `/brands/upload-font`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => FontUploadResponseFromJSON(jsonValue));
  }

  /**
   */
  async uploadFont(
    file_name: string,
    brand_id: string,
    font_file: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FontUploadResponse> {
    const response = await this.uploadFontRaw({ file_name: file_name, brand_id: brand_id, font_file: font_file }, initOverrides);
    return await response.value();
  }
}
