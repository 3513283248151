/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FontDeclaration
 */
export interface FontDeclaration {
  /**
   *
   * @type {string}
   * @memberof FontDeclaration
   */
  family: string;
  /**
   *
   * @type {string}
   * @memberof FontDeclaration
   */
  weight: string;
  /**
   *
   * @type {string}
   * @memberof FontDeclaration
   */
  style: string;
}

/**
 * Check if a given object implements the FontDeclaration interface.
 */
export function instanceOfFontDeclaration(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'family' in value;
  isInstance = isInstance && 'weight' in value;
  isInstance = isInstance && 'style' in value;

  return isInstance;
}

export function FontDeclarationFromJSON(json: any): FontDeclaration {
  return FontDeclarationFromJSONTyped(json, false);
}

export function FontDeclarationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FontDeclaration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    family: json['family'],
    weight: json['weight'],
    style: json['style'],
  };
}

export function FontDeclarationToJSON(value?: FontDeclaration | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    family: value.family,
    weight: value.weight,
    style: value.style,
  };
}
