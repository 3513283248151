// @mui
import { Stack } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/AuthContext';
// components
import { LoadingButton } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { loginWithGoogle, loginWithCloudinary } = useAuthContext();
  let [searchParams, _seSearchParams] = useSearchParams();
  const fromConsole = searchParams.get('utm_source') === 'cloudinary' && searchParams.get('utm_medium') === 'console';
  let cldButtonLoginStyle: Record<string, any> = {};
  let cldIconStyle: Record<string, any> = { color: '#3448C5' };
  if (fromConsole) {
    cldButtonLoginStyle = {
      backgroundColor: '#3448C5',
      color: '#ffffff',
      '&:hover': { backgroundColor: '#2a399d', color: '#ffffff' },
    };
    cldIconStyle = { color: '#ffffff' };
  }
  const handleGoogleLogin = async () => {
    try {
      if (loginWithGoogle) {
        loginWithGoogle();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloudinaryLogin = async () => {
    try {
      if (loginWithCloudinary) {
        loginWithCloudinary();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack spacing={2}>
      <LoadingButton
        fullWidth
        color={!isMobile ? 'inherit' : 'muted'}
        size='large'
        type='submit'
        variant='contained'
        loading={false}
        startIcon={<Iconify icon='eva:google-fill' color='#4285F4' />}
        onClick={handleGoogleLogin}
      >
        Login with google
      </LoadingButton>

      <LoadingButton
        fullWidth
        sx={cldButtonLoginStyle}
        size='large'
        type='submit'
        color={!isMobile ? 'inherit' : 'muted'}
        variant='contained'
        loading={false}
        startIcon={<Iconify icon='simple-icons:cloudinary' sx={cldIconStyle} />}
        onClick={handleCloudinaryLogin}
      >
        Login With Cloudinary
      </LoadingButton>
    </Stack>
  );
}
