import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthContext } from 'src/auth/AuthContext';
import { User } from '../../generated/app_server_sdk';

export function OnboardingGuard({ children }: { children: ReactNode }) {
  const authContext = useAuthContext();
  const user = authContext.user as User;

  if (!user.has_submitted_onboarding_form) {
    return <Navigate to='/onboarding' />;
  }

  return <>{children}</>;
}
