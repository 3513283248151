/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TextExtract
 */
export interface TextExtract {
  /**
   *
   * @type {string}
   * @memberof TextExtract
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof TextExtract
   */
  top: number;
  /**
   *
   * @type {number}
   * @memberof TextExtract
   */
  left: number;
  /**
   *
   * @type {number}
   * @memberof TextExtract
   */
  bottom: number;
  /**
   *
   * @type {number}
   * @memberof TextExtract
   */
  right: number;
  /**
   *
   * @type {string}
   * @memberof TextExtract
   */
  text_detection_score: string;
  /**
   *
   * @type {string}
   * @memberof TextExtract
   */
  font_name: string;
  /**
   *
   * @type {string}
   * @memberof TextExtract
   */
  weight: TextExtractWeightEnum;
  /**
   *
   * @type {string}
   * @memberof TextExtract
   */
  italic: TextExtractItalicEnum;
  /**
   *
   * @type {number}
   * @memberof TextExtract
   */
  font_detection_score: number;
}

/**
 * @export
 */
export const TextExtractWeightEnum = {
  Normal: 'normal',
  Bold: 'bold',
} as const;
export type TextExtractWeightEnum = (typeof TextExtractWeightEnum)[keyof typeof TextExtractWeightEnum];

/**
 * @export
 */
export const TextExtractItalicEnum = {
  Normal: 'normal',
  Italic: 'italic',
} as const;
export type TextExtractItalicEnum = (typeof TextExtractItalicEnum)[keyof typeof TextExtractItalicEnum];

/**
 * Check if a given object implements the TextExtract interface.
 */
export function instanceOfTextExtract(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'text' in value;
  isInstance = isInstance && 'top' in value;
  isInstance = isInstance && 'left' in value;
  isInstance = isInstance && 'bottom' in value;
  isInstance = isInstance && 'right' in value;
  isInstance = isInstance && 'text_detection_score' in value;
  isInstance = isInstance && 'font_name' in value;
  isInstance = isInstance && 'weight' in value;
  isInstance = isInstance && 'italic' in value;
  isInstance = isInstance && 'font_detection_score' in value;

  return isInstance;
}

export function TextExtractFromJSON(json: any): TextExtract {
  return TextExtractFromJSONTyped(json, false);
}

export function TextExtractFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextExtract {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text: json['text'],
    top: json['top'],
    left: json['left'],
    bottom: json['bottom'],
    right: json['right'],
    text_detection_score: json['text_detection_score'],
    font_name: json['font_name'],
    weight: json['weight'],
    italic: json['italic'],
    font_detection_score: json['font_detection_score'],
  };
}

export function TextExtractToJSON(value?: TextExtract | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text: value.text,
    top: value.top,
    left: value.left,
    bottom: value.bottom,
    right: value.right,
    text_detection_score: value.text_detection_score,
    font_name: value.font_name,
    weight: value.weight,
    italic: value.italic,
    font_detection_score: value.font_detection_score,
  };
}
