/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateCloudinaryIntegrationRequest
 */
export interface UpdateCloudinaryIntegrationRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateCloudinaryIntegrationRequest
   */
  cld_secret_key: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCloudinaryIntegrationRequest
   */
  cld_api_key: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCloudinaryIntegrationRequest
   */
  cld_cloud_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCloudinaryIntegrationRequest
   */
  organization_id: string;
}

/**
 * Check if a given object implements the UpdateCloudinaryIntegrationRequest interface.
 */
export function instanceOfUpdateCloudinaryIntegrationRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cld_secret_key' in value;
  isInstance = isInstance && 'cld_api_key' in value;
  isInstance = isInstance && 'cld_cloud_name' in value;
  isInstance = isInstance && 'organization_id' in value;

  return isInstance;
}

export function UpdateCloudinaryIntegrationRequestFromJSON(json: any): UpdateCloudinaryIntegrationRequest {
  return UpdateCloudinaryIntegrationRequestFromJSONTyped(json, false);
}

export function UpdateCloudinaryIntegrationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCloudinaryIntegrationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cld_secret_key: json['cld_secret_key'],
    cld_api_key: json['cld_api_key'],
    cld_cloud_name: json['cld_cloud_name'],
    organization_id: json['organization_id'],
  };
}

export function UpdateCloudinaryIntegrationRequestToJSON(value?: UpdateCloudinaryIntegrationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cld_secret_key: value.cld_secret_key,
    cld_api_key: value.cld_api_key,
    cld_cloud_name: value.cld_cloud_name,
    organization_id: value.organization_id,
  };
}
