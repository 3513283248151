/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface GetImageColors200Response
 */
export interface GetImageColors200Response {
  /**
   *
   * @type {string}
   * @memberof GetImageColors200Response
   */
  url?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetImageColors200Response
   */
  colors?: Array<string>;
}

/**
 * Check if a given object implements the GetImageColors200Response interface.
 */
export function instanceOfGetImageColors200Response(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function GetImageColors200ResponseFromJSON(json: any): GetImageColors200Response {
  return GetImageColors200ResponseFromJSONTyped(json, false);
}

export function GetImageColors200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetImageColors200Response {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: !exists(json, 'url') ? undefined : json['url'],
    colors: !exists(json, 'colors') ? undefined : json['colors'],
  };
}

export function GetImageColors200ResponseToJSON(value?: GetImageColors200Response | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
    colors: value.colors,
  };
}
