/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AssetUpdateRequestPayload
 */
export interface AssetUpdateRequestPayload {
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequestPayload
   */
  product_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequestPayload
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequestPayload
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof AssetUpdateRequestPayload
   */
  custom_size?: number;
  /**
   *
   * @type {object}
   * @memberof AssetUpdateRequestPayload
   */
  transparent_image?: object;
  /**
   *
   * @type {object}
   * @memberof AssetUpdateRequestPayload
   */
  original_image?: object;
  /**
   *
   * @type {object}
   * @memberof AssetUpdateRequestPayload
   */
  canvas?: object;
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequestPayload
   */
  sharing?: AssetUpdateRequestPayloadSharingEnum;
  /**
   *
   * @type {string}
   * @memberof AssetUpdateRequestPayload
   */
  organization_id?: string;
}

/**
 * @export
 */
export const AssetUpdateRequestPayloadSharingEnum = {
  Private: 'private',
  Public: 'public',
  Organization: 'organization',
} as const;
export type AssetUpdateRequestPayloadSharingEnum = (typeof AssetUpdateRequestPayloadSharingEnum)[keyof typeof AssetUpdateRequestPayloadSharingEnum];

/**
 * Check if a given object implements the AssetUpdateRequestPayload interface.
 */
export function instanceOfAssetUpdateRequestPayload(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AssetUpdateRequestPayloadFromJSON(json: any): AssetUpdateRequestPayload {
  return AssetUpdateRequestPayloadFromJSONTyped(json, false);
}

export function AssetUpdateRequestPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetUpdateRequestPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    product_id: !exists(json, 'product_id') ? undefined : json['product_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    custom_size: !exists(json, 'custom_size') ? undefined : json['custom_size'],
    transparent_image: !exists(json, 'transparent_image') ? undefined : json['transparent_image'],
    original_image: !exists(json, 'original_image') ? undefined : json['original_image'],
    canvas: !exists(json, 'canvas') ? undefined : json['canvas'],
    sharing: !exists(json, 'sharing') ? undefined : json['sharing'],
    organization_id: !exists(json, 'organization_id') ? undefined : json['organization_id'],
  };
}

export function AssetUpdateRequestPayloadToJSON(value?: AssetUpdateRequestPayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_id: value.product_id,
    name: value.name,
    description: value.description,
    custom_size: value.custom_size,
    transparent_image: value.transparent_image,
    original_image: value.original_image,
    canvas: value.canvas,
    sharing: value.sharing,
    organization_id: value.organization_id,
  };
}
