import { Box, Collapse, Divider, Drawer, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import LABS_LIST from 'src/assets/data/labs';
import Iconify from '../iconify/Iconify';
import { isMobile } from 'react-device-detect';
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover';

const MOBILE_LABS_LIST = ['/labs/text-to-image', '/labs/inpaint', '/labs/restyle-image', '/labs/text-to-subject'].map(
  (url) => LABS_LIST.find((lab) => lab.navigateUrl === url) as (typeof LABS_LIST)[0],
);

export default function MobileHeader() {
  let [drawerOpen, setDrawerOpen] = useState(false);

  let [isLabsSubmenuOpen, setIsLabsSubmenuOpen] = useState(false);

  let navigate = useNavigate();

  return (
    <>
      <Fab
        color='primary'
        aria-label='menu'
        sx={{
          position: 'fixed',
          top: '1rem',
          right: '1rem',
          padding: '0.25rem',
          width: '2.5rem',
          height: '2.5rem',
          display: window.location.pathname === '/' ? 'block' : 'none',
        }}
      >
        <AccountPopover />
      </Fab>
      <Drawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <Box sx={{ height: '55px', overflow: 'hidden', width: 300 }}>
          <IconButton
            sx={{ marginTop: '5px' }}
            onClick={() => {
              setDrawerOpen(false);
            }}
          >
            <Iconify icon='material-symbols:chevron-left' width={33} height={22} />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/');
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Iconify icon='material-symbols:home' />
              </ListItemIcon>
              <ListItemText primary='Home' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              onClick={(event) => {
                if (isMobile) {
                  setIsLabsSubmenuOpen((curr) => !curr);
                  event.stopPropagation();
                  return;
                }
                navigate('/#labs');
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Iconify icon='material-symbols:camera' />
              </ListItemIcon>
              <ListItemText primary='Labs' />
              <IconButton
                onClick={(event) => {
                  setIsLabsSubmenuOpen((curr) => !curr);
                  event.stopPropagation();
                }}
              >
                {isLabsSubmenuOpen ? <Iconify icon='ic:baseline-expand-less' /> : <Iconify icon='ic:baseline-expand-more' />}
              </IconButton>
            </ListItemButton>
          </ListItem>
          <Collapse in={isLabsSubmenuOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {MOBILE_LABS_LIST.map((lab) => (
                <ListItemButton
                  key={lab.navigateUrl}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    navigate(lab.navigateUrl);
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon={lab.icon} />
                  </ListItemIcon>
                  <ListItemText primary={lab.name} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('account/settings');
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Iconify icon='material-symbols:settings' />
              </ListItemIcon>
              <ListItemText primary={'Settings'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
