/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  ContentAnalysisResponse,
  GetImageColors200Response,
  ImageGenerativeFillByMaskRequest,
  ImageObjectRemovalByMaskRequest,
  InferImageDescription200Response,
  SaveImage200Response,
  TextExtractResponse,
} from '../models';
import {
  ContentAnalysisResponseFromJSON,
  ContentAnalysisResponseToJSON,
  GetImageColors200ResponseFromJSON,
  GetImageColors200ResponseToJSON,
  ImageGenerativeFillByMaskRequestFromJSON,
  ImageGenerativeFillByMaskRequestToJSON,
  ImageObjectRemovalByMaskRequestFromJSON,
  ImageObjectRemovalByMaskRequestToJSON,
  InferImageDescription200ResponseFromJSON,
  InferImageDescription200ResponseToJSON,
  SaveImage200ResponseFromJSON,
  SaveImage200ResponseToJSON,
  TextExtractResponseFromJSON,
  TextExtractResponseToJSON,
} from '../models';

export interface ImageProcessingApiAnalyzeImageContentRequest {
  image: Blob;
}

export interface ImageProcessingApiExtractTextRequest {
  image: Blob;
}

export interface ImageProcessingApiGetImageColorsRequest {
  image: Blob;
  folder?: string;
}

export interface ImageProcessingApiImageGenerativeFillByMaskOperationRequest {
  image_generative_fill_by_mask_request?: ImageGenerativeFillByMaskRequest;
}

export interface ImageProcessingApiImageObjectRemovalRequest {
  refiner_prompt: string;
  refiner_strength: number;
  delete_prompt: string;
  bbox_right: number;
  bbox_bottom: number;
  bbox_left: number;
  bbox_top: number;
  image: Blob;
}

export interface ImageProcessingApiImageObjectRemovalByMaskOperationRequest {
  image_object_removal_by_mask_request?: ImageObjectRemovalByMaskRequest;
}

export interface ImageProcessingApiInferImageDescriptionRequest {
  image: Blob;
}

export interface ImageProcessingApiRefineFillByMaskRequest {
  mask: Blob;
  image: Blob;
}

export interface ImageProcessingApiSaveImageRequest {
  image: Blob;
  folder?: string;
}

export interface ImageProcessingApiSegmentImageBackgroundRequest {
  render: SegmentImageBackgroundRenderEnum;
  image: Blob;
}

/**
 *
 */
export class ImageProcessingApi extends runtime.BaseAPI {
  /**
   */
  async analyzeImageContentRaw(
    requestParameters: ImageProcessingApiAnalyzeImageContentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ContentAnalysisResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling analyzeImageContent.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/analyze-image-content`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ContentAnalysisResponseFromJSON(jsonValue));
  }

  /**
   */
  async analyzeImageContent(image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContentAnalysisResponse> {
    const response = await this.analyzeImageContentRaw({ image: image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async extractTextRaw(
    requestParameters: ImageProcessingApiExtractTextRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TextExtractResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling extractText.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/extract-text`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TextExtractResponseFromJSON(jsonValue));
  }

  /**
   */
  async extractText(image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TextExtractResponse> {
    const response = await this.extractTextRaw({ image: image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async getImageColorsRaw(
    requestParameters: ImageProcessingApiGetImageColorsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetImageColors200Response>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling getImageColors.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.folder !== undefined) {
      formParams.append('folder', requestParameters.folder as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/get-image-colors`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetImageColors200ResponseFromJSON(jsonValue));
  }

  /**
   */
  async getImageColors(image: Blob, folder?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetImageColors200Response> {
    const response = await this.getImageColorsRaw({ image: image, folder: folder }, initOverrides);
    return await response.value();
  }

  /**
   */
  async imageGenerativeFillByMaskRaw(
    requestParameters: ImageProcessingApiImageGenerativeFillByMaskOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image-processing/image_generative_fill_by_mask`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ImageGenerativeFillByMaskRequestToJSON(requestParameters.image_generative_fill_by_mask_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async imageGenerativeFillByMask(
    image_generative_fill_by_mask_request?: ImageGenerativeFillByMaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.imageGenerativeFillByMaskRaw({ image_generative_fill_by_mask_request: image_generative_fill_by_mask_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async imageObjectRemovalRaw(
    requestParameters: ImageProcessingApiImageObjectRemovalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.refiner_prompt === null || requestParameters.refiner_prompt === undefined) {
      throw new runtime.RequiredError(
        'refiner_prompt',
        'Required parameter requestParameters.refiner_prompt was null or undefined when calling imageObjectRemoval.',
      );
    }

    if (requestParameters.refiner_strength === null || requestParameters.refiner_strength === undefined) {
      throw new runtime.RequiredError(
        'refiner_strength',
        'Required parameter requestParameters.refiner_strength was null or undefined when calling imageObjectRemoval.',
      );
    }

    if (requestParameters.delete_prompt === null || requestParameters.delete_prompt === undefined) {
      throw new runtime.RequiredError(
        'delete_prompt',
        'Required parameter requestParameters.delete_prompt was null or undefined when calling imageObjectRemoval.',
      );
    }

    if (requestParameters.bbox_right === null || requestParameters.bbox_right === undefined) {
      throw new runtime.RequiredError('bbox_right', 'Required parameter requestParameters.bbox_right was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.bbox_bottom === null || requestParameters.bbox_bottom === undefined) {
      throw new runtime.RequiredError('bbox_bottom', 'Required parameter requestParameters.bbox_bottom was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.bbox_left === null || requestParameters.bbox_left === undefined) {
      throw new runtime.RequiredError('bbox_left', 'Required parameter requestParameters.bbox_left was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.bbox_top === null || requestParameters.bbox_top === undefined) {
      throw new runtime.RequiredError('bbox_top', 'Required parameter requestParameters.bbox_top was null or undefined when calling imageObjectRemoval.');
    }

    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling imageObjectRemoval.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.refiner_prompt !== undefined) {
      formParams.append('refiner_prompt', requestParameters.refiner_prompt as any);
    }

    if (requestParameters.refiner_strength !== undefined) {
      formParams.append('refiner_strength', requestParameters.refiner_strength as any);
    }

    if (requestParameters.delete_prompt !== undefined) {
      formParams.append('delete_prompt', requestParameters.delete_prompt as any);
    }

    if (requestParameters.bbox_right !== undefined) {
      formParams.append('bbox_right', requestParameters.bbox_right as any);
    }

    if (requestParameters.bbox_bottom !== undefined) {
      formParams.append('bbox_bottom', requestParameters.bbox_bottom as any);
    }

    if (requestParameters.bbox_left !== undefined) {
      formParams.append('bbox_left', requestParameters.bbox_left as any);
    }

    if (requestParameters.bbox_top !== undefined) {
      formParams.append('bbox_top', requestParameters.bbox_top as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/image-object-removal`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async imageObjectRemoval(
    refiner_prompt: string,
    refiner_strength: number,
    delete_prompt: string,
    bbox_right: number,
    bbox_bottom: number,
    bbox_left: number,
    bbox_top: number,
    image: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.imageObjectRemovalRaw(
      {
        refiner_prompt: refiner_prompt,
        refiner_strength: refiner_strength,
        delete_prompt: delete_prompt,
        bbox_right: bbox_right,
        bbox_bottom: bbox_bottom,
        bbox_left: bbox_left,
        bbox_top: bbox_top,
        image: image,
      },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async imageObjectRemovalByMaskRaw(
    requestParameters: ImageProcessingApiImageObjectRemovalByMaskOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/image-processing/image_object_removal_by_mask`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ImageObjectRemovalByMaskRequestToJSON(requestParameters.image_object_removal_by_mask_request),
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async imageObjectRemovalByMask(
    image_object_removal_by_mask_request?: ImageObjectRemovalByMaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.imageObjectRemovalByMaskRaw({ image_object_removal_by_mask_request: image_object_removal_by_mask_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async inferImageDescriptionRaw(
    requestParameters: ImageProcessingApiInferImageDescriptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InferImageDescription200Response>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling inferImageDescription.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/infer-image-description`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => InferImageDescription200ResponseFromJSON(jsonValue));
  }

  /**
   */
  async inferImageDescription(image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InferImageDescription200Response> {
    const response = await this.inferImageDescriptionRaw({ image: image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async refineFillByMaskRaw(
    requestParameters: ImageProcessingApiRefineFillByMaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.mask === null || requestParameters.mask === undefined) {
      throw new runtime.RequiredError('mask', 'Required parameter requestParameters.mask was null or undefined when calling refineFillByMask.');
    }

    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling refineFillByMask.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.mask !== undefined) {
      formParams.append('mask', requestParameters.mask as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/refine_fill_by_mask`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async refineFillByMask(mask: Blob, image: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
    const response = await this.refineFillByMaskRaw({ mask: mask, image: image }, initOverrides);
    return await response.value();
  }

  /**
   */
  async saveImageRaw(
    requestParameters: ImageProcessingApiSaveImageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SaveImage200Response>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling saveImage.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.folder !== undefined) {
      formParams.append('folder', requestParameters.folder as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/save-image`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SaveImage200ResponseFromJSON(jsonValue));
  }

  /**
   */
  async saveImage(image: Blob, folder?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SaveImage200Response> {
    const response = await this.saveImageRaw({ image: image, folder: folder }, initOverrides);
    return await response.value();
  }

  /**
   */
  async segmentImageBackgroundRaw(
    requestParameters: ImageProcessingApiSegmentImageBackgroundRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.render === null || requestParameters.render === undefined) {
      throw new runtime.RequiredError('render', 'Required parameter requestParameters.render was null or undefined when calling segmentImageBackground.');
    }

    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling segmentImageBackground.');
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.render !== undefined) {
      formParams.append('render', requestParameters.render as any);
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/image-processing/segment-image-background`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   */
  async segmentImageBackground(
    render: SegmentImageBackgroundRenderEnum,
    image: Blob,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Blob> {
    const response = await this.segmentImageBackgroundRaw({ render: render, image: image }, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const SegmentImageBackgroundRenderEnum = {
  Image: 'image',
  Mask: 'mask',
} as const;
export type SegmentImageBackgroundRenderEnum = (typeof SegmentImageBackgroundRenderEnum)[keyof typeof SegmentImageBackgroundRenderEnum];
