/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateDamIntegrationSettingsRequest
 */
export interface UpdateDamIntegrationSettingsRequest {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDamIntegrationSettingsRequest
   */
  cld_open_dam_app_in_new_window: boolean;
}

/**
 * Check if a given object implements the UpdateDamIntegrationSettingsRequest interface.
 */
export function instanceOfUpdateDamIntegrationSettingsRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cld_open_dam_app_in_new_window' in value;

  return isInstance;
}

export function UpdateDamIntegrationSettingsRequestFromJSON(json: any): UpdateDamIntegrationSettingsRequest {
  return UpdateDamIntegrationSettingsRequestFromJSONTyped(json, false);
}

export function UpdateDamIntegrationSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDamIntegrationSettingsRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cld_open_dam_app_in_new_window: json['cld_open_dam_app_in_new_window'],
  };
}

export function UpdateDamIntegrationSettingsRequestToJSON(value?: UpdateDamIntegrationSettingsRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cld_open_dam_app_in_new_window: value.cld_open_dam_app_in_new_window,
  };
}
