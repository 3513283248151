/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LabsVirtualPhotoshootRequest
 */
export interface LabsVirtualPhotoshootRequest {
  /**
   *
   * @type {number}
   * @memberof LabsVirtualPhotoshootRequest
   */
  height: number;
  /**
   *
   * @type {number}
   * @memberof LabsVirtualPhotoshootRequest
   */
  width: number;
  /**
   *
   * @type {string}
   * @memberof LabsVirtualPhotoshootRequest
   */
  scene_id: string;
  /**
   *
   * @type {string}
   * @memberof LabsVirtualPhotoshootRequest
   */
  asset_id: string;
  /**
   *
   * @type {object}
   * @memberof LabsVirtualPhotoshootRequest
   */
  placement_canvas_content: object;
  /**
   *
   * @type {string}
   * @memberof LabsVirtualPhotoshootRequest
   */
  prompt: string;
}

/**
 * Check if a given object implements the LabsVirtualPhotoshootRequest interface.
 */
export function instanceOfLabsVirtualPhotoshootRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'height' in value;
  isInstance = isInstance && 'width' in value;
  isInstance = isInstance && 'scene_id' in value;
  isInstance = isInstance && 'asset_id' in value;
  isInstance = isInstance && 'placement_canvas_content' in value;
  isInstance = isInstance && 'prompt' in value;

  return isInstance;
}

export function LabsVirtualPhotoshootRequestFromJSON(json: any): LabsVirtualPhotoshootRequest {
  return LabsVirtualPhotoshootRequestFromJSONTyped(json, false);
}

export function LabsVirtualPhotoshootRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabsVirtualPhotoshootRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    height: json['height'],
    width: json['width'],
    scene_id: json['scene_id'],
    asset_id: json['asset_id'],
    placement_canvas_content: json['placement_canvas_content'],
    prompt: json['prompt'],
  };
}

export function LabsVirtualPhotoshootRequestToJSON(value?: LabsVirtualPhotoshootRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    height: value.height,
    width: value.width,
    scene_id: value.scene_id,
    asset_id: value.asset_id,
    placement_canvas_content: value.placement_canvas_content,
    prompt: value.prompt,
  };
}
