export async function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function untilTrue(predicate: () => boolean, options: { timeout: number; interval?: number; error_message?: string }): Promise<boolean> {
  if (!options.interval) {
    options.interval = 100;
  }
  if (!options.error_message) {
    options.error_message = 'Timeout Exceeded';
  }
  const startTime = Date.now();
  while (!predicate()) {
    if (Date.now() - startTime >= options.timeout) {
      return false;
    }
    await timeout(options.interval);
  }
  return true;
}

export async function untilTrueAsync(
  predicate: () => Promise<boolean>,
  options: { timeout: number; interval?: number; error_message?: string; silent?: boolean },
): Promise<void> {
  if (!options.interval) {
    options.interval = 100;
  }
  if (!options.error_message) {
    options.error_message = 'Timeout Exceeded';
  }
  const startTime = Date.now();

  let result = false;
  while (!result) {
    try {
      result = await predicate();
      // exit early if the predicate returns true
      if (result === true) {
        return;
      }
    } catch (e: any) {}

    if (Date.now() - startTime >= options.timeout) {
      if (!options.silent) {
        throw new Error(options.error_message);
      }
    }
    await new Promise((resolve) => setTimeout(resolve, options.interval));
  }
}
