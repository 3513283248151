import React from 'react';
import { default as ReactDropzone, DropzoneProps, FileRejection } from 'react-dropzone';
import { useSnackbar } from '../snackbar';
import { truncate } from 'lodash';

export default function Dropzone(props: DropzoneProps) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <ReactDropzone
      onDropRejected={(fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
          enqueueSnackbar(
            `Couldn't process ${truncate(fileRejections.map((fileRejection) => fileRejection.file.name).join(', '), {
              length: 30,
            })}.`,
            {
              key: 'UPLOAD_FAILED',
              preventDuplicate: false,
              autoHideDuration: 5000,
              variant: 'error',
              anchorOrigin: { horizontal: 'right', vertical: 'top' },
            },
          );
        }
      }}
      {...props}
    />
  );
}
