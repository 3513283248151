/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  openreplay: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  enable_client_side_logging: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  global_usage_tracking: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  cld_cloud_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  cld_api_key?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  cld_environment_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  cld_open_dam_app_in_new_window?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  allow_bulk_testing?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  lora_training_and_usage: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  asset_collaboration: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  santas_wonderland_enabled: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  editor_custom_canvas_sizes: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  editor_responsive_resize: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  brand_kit_limit: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  editor_assets_limit: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  cloudinary_dam_integration: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  cloudinary_pm_integration: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  editor_custom_font?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  current_organization_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  allow_text_extraction?: string;
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  education_enabled?: string;
}

/**
 * Check if a given object implements the UserSettings interface.
 */
export function instanceOfUserSettings(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'openreplay' in value;
  isInstance = isInstance && 'enable_client_side_logging' in value;
  isInstance = isInstance && 'global_usage_tracking' in value;
  isInstance = isInstance && 'lora_training_and_usage' in value;
  isInstance = isInstance && 'asset_collaboration' in value;
  isInstance = isInstance && 'santas_wonderland_enabled' in value;
  isInstance = isInstance && 'editor_custom_canvas_sizes' in value;
  isInstance = isInstance && 'editor_responsive_resize' in value;
  isInstance = isInstance && 'brand_kit_limit' in value;
  isInstance = isInstance && 'editor_assets_limit' in value;
  isInstance = isInstance && 'cloudinary_dam_integration' in value;
  isInstance = isInstance && 'cloudinary_pm_integration' in value;

  return isInstance;
}

export function UserSettingsFromJSON(json: any): UserSettings {
  return UserSettingsFromJSONTyped(json, false);
}

export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    openreplay: json['openreplay'],
    enable_client_side_logging: json['enable_client_side_logging'],
    global_usage_tracking: json['global_usage_tracking'],
    cld_cloud_name: !exists(json, 'cld_cloud_name') ? undefined : json['cld_cloud_name'],
    cld_api_key: !exists(json, 'cld_api_key') ? undefined : json['cld_api_key'],
    cld_environment_id: !exists(json, 'cld_environment_id') ? undefined : json['cld_environment_id'],
    cld_open_dam_app_in_new_window: !exists(json, 'cld_open_dam_app_in_new_window') ? undefined : json['cld_open_dam_app_in_new_window'],
    allow_bulk_testing: !exists(json, 'allow_bulk_testing') ? undefined : json['allow_bulk_testing'],
    lora_training_and_usage: json['lora_training_and_usage'],
    asset_collaboration: json['asset_collaboration'],
    santas_wonderland_enabled: json['santas_wonderland_enabled'],
    editor_custom_canvas_sizes: json['editor_custom_canvas_sizes'],
    editor_responsive_resize: json['editor_responsive_resize'],
    brand_kit_limit: json['brand_kit_limit'],
    editor_assets_limit: json['editor_assets_limit'],
    cloudinary_dam_integration: json['cloudinary_dam_integration'],
    cloudinary_pm_integration: json['cloudinary_pm_integration'],
    editor_custom_font: !exists(json, 'editor_custom_font') ? undefined : json['editor_custom_font'],
    current_organization_id: !exists(json, 'current_organization_id') ? undefined : json['current_organization_id'],
    allow_text_extraction: !exists(json, 'allow_text_extraction') ? undefined : json['allow_text_extraction'],
    education_enabled: !exists(json, 'education_enabled') ? undefined : json['education_enabled'],
  };
}

export function UserSettingsToJSON(value?: UserSettings | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    openreplay: value.openreplay,
    enable_client_side_logging: value.enable_client_side_logging,
    global_usage_tracking: value.global_usage_tracking,
    cld_cloud_name: value.cld_cloud_name,
    cld_api_key: value.cld_api_key,
    cld_environment_id: value.cld_environment_id,
    cld_open_dam_app_in_new_window: value.cld_open_dam_app_in_new_window,
    allow_bulk_testing: value.allow_bulk_testing,
    lora_training_and_usage: value.lora_training_and_usage,
    asset_collaboration: value.asset_collaboration,
    santas_wonderland_enabled: value.santas_wonderland_enabled,
    editor_custom_canvas_sizes: value.editor_custom_canvas_sizes,
    editor_responsive_resize: value.editor_responsive_resize,
    brand_kit_limit: value.brand_kit_limit,
    editor_assets_limit: value.editor_assets_limit,
    cloudinary_dam_integration: value.cloudinary_dam_integration,
    cloudinary_pm_integration: value.cloudinary_pm_integration,
    editor_custom_font: value.editor_custom_font,
    current_organization_id: value.current_organization_id,
    allow_text_extraction: value.allow_text_extraction,
    education_enabled: value.education_enabled,
  };
}
