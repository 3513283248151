/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CloudinaryCloudAsset
 */
export interface CloudinaryCloudAsset {
  /**
   *
   * @type {string}
   * @memberof CloudinaryCloudAsset
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryCloudAsset
   */
  cloud_name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CloudinaryCloudAsset
   */
  named_transformations: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CloudinaryCloudAsset
   */
  named_transformations_base_layer: string;
}

/**
 * Check if a given object implements the CloudinaryCloudAsset interface.
 */
export function instanceOfCloudinaryCloudAsset(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'cloud_name' in value;
  isInstance = isInstance && 'named_transformations' in value;
  isInstance = isInstance && 'named_transformations_base_layer' in value;

  return isInstance;
}

export function CloudinaryCloudAssetFromJSON(json: any): CloudinaryCloudAsset {
  return CloudinaryCloudAssetFromJSONTyped(json, false);
}

export function CloudinaryCloudAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryCloudAsset {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    cloud_name: json['cloud_name'],
    named_transformations: json['named_transformations'],
    named_transformations_base_layer: json['named_transformations_base_layer'],
  };
}

export function CloudinaryCloudAssetToJSON(value?: CloudinaryCloudAsset | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    cloud_name: value.cloud_name,
    named_transformations: value.named_transformations,
    named_transformations_base_layer: value.named_transformations_base_layer,
  };
}
