/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudinarySearchAsset } from './CloudinarySearchAsset';
import { CloudinarySearchAssetFromJSON, CloudinarySearchAssetFromJSONTyped, CloudinarySearchAssetToJSON } from './CloudinarySearchAsset';

/**
 *
 * @export
 * @interface SearchCloudinaryAssetsResponse
 */
export interface SearchCloudinaryAssetsResponse {
  /**
   *
   * @type {Array<CloudinarySearchAsset>}
   * @memberof SearchCloudinaryAssetsResponse
   */
  items: Array<CloudinarySearchAsset>;
}

/**
 * Check if a given object implements the SearchCloudinaryAssetsResponse interface.
 */
export function instanceOfSearchCloudinaryAssetsResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'items' in value;

  return isInstance;
}

export function SearchCloudinaryAssetsResponseFromJSON(json: any): SearchCloudinaryAssetsResponse {
  return SearchCloudinaryAssetsResponseFromJSONTyped(json, false);
}

export function SearchCloudinaryAssetsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchCloudinaryAssetsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(CloudinarySearchAssetFromJSON),
  };
}

export function SearchCloudinaryAssetsResponseToJSON(value?: SearchCloudinaryAssetsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(CloudinarySearchAssetToJSON),
  };
}
