import { Box, Chip } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useCreditsContext } from 'src/components/credits-context/CreditsContext';
import { UserSettings } from 'src/generated/app_server_sdk';
import { useAuthContext } from './AuthContext';

type FeatureGate = {
  flag: keyof UserSettings;
  package?: 'designer' | 'team';
} & ({ value: string; predicate?: never } | { predicate: (user: UserSettings) => boolean; value?: never });

export function useFeatureGate() {
  const { openUpgradeModal } = useCreditsContext();
  const { user } = useAuthContext();
  const wrapWithFeatureGate = (handler: (() => void) | undefined, options: FeatureGate) => {
    if (handler === undefined) {
      return () => {};
    }
    return () => {
      if (!showModalIfFeatureDisabled(options)) {
        return handler();
      }
    };
  };

  const showModalIfFeatureDisabled = (options: FeatureGate) => {
    options.predicate = options.predicate ?? ((userSettings) => userSettings[options.flag] === options.value);
    if (!user?.settings || !options.predicate(user.settings)) {
      openUpgradeModal(options.package);
      return true;
    }
    return false;
  };

  return { wrapWithFeatureGate, showModalIfFeatureDisabled };
}
type FeatureGrateProps = {
  flag: keyof UserSettings;
  value: string;
  navigateOnError: string;
};
export function NavigationFeatureGate(props: FeatureGrateProps) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  useEffect(() => {
    if (user && user.settings && user.settings[props.flag] !== props.value) {
      navigate(props.navigateOnError);
    }
  }, [user, props, navigate]);
  return <Outlet />;
}

type RenderingFeatureGateProps = FeatureGate & {
  value: string;
  children: React.ReactNode;
};

export function RenderingFeatureGate(props: RenderingFeatureGateProps) {
  const { user } = useAuthContext();
  if (user && user.settings && user.settings[props.flag] !== props.value) {
    return <></>;
  }
  return <>{props.children}</>;
}

interface OptionalFeatureGateProps extends Omit<RenderingFeatureGateProps, 'flag'> {
  flag: keyof UserSettings | undefined;
}
export function OptionalFeatureGate(props: OptionalFeatureGateProps) {
  if (typeof props.flag === 'undefined') {
    return <>{props.children}</>;
  }

  return (
    <RenderingFeatureGate flag={props.flag} value={props.value} predicate={props.predicate}>
      {props.children}
    </RenderingFeatureGate>
  );
}

type FTPackageWrapperProps = FeatureGate & {
  children: React.ReactNode;
  package: 'designer' | 'team';
  onClick?: (e: React.MouseEvent) => void;
};

export function FTPackageWrapper(props: FTPackageWrapperProps) {
  const { showModalIfFeatureDisabled } = useFeatureGate();
  const { openUpgradeModal } = useCreditsContext();

  return (
    <Box
      onMouseDownCapture={(e) => {
        let isModalDisplayed = showModalIfFeatureDisabled({
          flag: props.flag,
          package: props.package,
          ...(props.predicate ? { predicate: props.predicate } : { value: props.value }),
        });
        if (isModalDisplayed) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      onClick={props.onClick}
    >
      <Chip
        color='primary'
        variant='outlined'
        label={props.package}
        size='small'
        sx={{ borderRadius: 0.3, fontSize: '10px', height: '16px', cursor: 'pointer', float: 'right' }}
        onClick={() => {
          openUpgradeModal(props.package);
        }}
      />
      {props.children}
    </Box>
  );
}
