/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ScenePromptRefinementRequest
 */
export interface ScenePromptRefinementRequest {
  /**
   *
   * @type {string}
   * @memberof ScenePromptRefinementRequest
   */
  prompt: string;
  /**
   *
   * @type {string}
   * @memberof ScenePromptRefinementRequest
   */
  product_id: string;
}

/**
 * Check if a given object implements the ScenePromptRefinementRequest interface.
 */
export function instanceOfScenePromptRefinementRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'prompt' in value;
  isInstance = isInstance && 'product_id' in value;

  return isInstance;
}

export function ScenePromptRefinementRequestFromJSON(json: any): ScenePromptRefinementRequest {
  return ScenePromptRefinementRequestFromJSONTyped(json, false);
}

export function ScenePromptRefinementRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScenePromptRefinementRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    prompt: json['prompt'],
    product_id: json['product_id'],
  };
}

export function ScenePromptRefinementRequestToJSON(value?: ScenePromptRefinementRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    prompt: value.prompt,
    product_id: value.product_id,
  };
}
