import { isEqual } from 'lodash';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useEvent } from 'src/hooks/useEvent';
import { useLogger } from 'src/utils/Logger';
import useAsyncEffect from 'use-async-effect';
import { Credits, FinalTouchPackage } from '../../generated/app_server_sdk';
import { usePolling } from '../../hooks/usePolling';
import appServer from '../../utils/appServer';
import { useApi } from '../api/useApi';

type PlanTier = 'free' | 'designer' | 'team';

interface CreditsContextValue {
  credits: Credits | undefined;
  upgradeModalProps: { isOpen: boolean; highlightedPlan?: PlanTier };
  openUpgradeModal: (highlightedPlan?: PlanTier) => void;
  closeUpgradeModal: () => void;
  packages: Array<FinalTouchPackage>;
  updateCredits: () => void;
}
const CreditsContext = createContext<CreditsContextValue | null>(null);

interface CreditsContextProviderProps {
  children?: ReactNode;
}

export function useCreditsContext() {
  const value = useContext(CreditsContext);

  if (!value) {
    throw new Error('CreditsContext value not found');
  }

  return value;
}

export function CreditsContextProvider({ children }: CreditsContextProviderProps) {
  let logger = useLogger();
  const [credits, setCredits] = useState<Credits | undefined>();
  const [packages, setPackages] = useState<Array<FinalTouchPackage>>([]);
  const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);
  const [upgradeModalProps, setUpgradeModalProps] = useState<{ highlightedPlan: PlanTier } | undefined>();

  const api = useApi();

  useAsyncEffect(async () => {
    const [, response] = await api.wrapResult(() => api.finaltouch.utilsApi.finalTouchPackages());
    if (response) {
      setPackages(response.items);
    }
  }, []);

  const { forcePoll: updateCredits } = usePolling({
    enabled: true,
    interval: 300000,
    client: async () => {
      try {
        const creditsResponse = await appServer.photosApi.credits();
        if (!isEqual(credits, creditsResponse)) {
          setCredits(creditsResponse);
        }
      } catch (e) {
        logger.debug(e);
      }
    },
  });

  const openUpgradeModal = useEvent((highlightedPlan: PlanTier = 'designer') => {
    setUpgradeModalOpened(true);
    setUpgradeModalProps({ highlightedPlan });
  });

  const closeUpgradeModal = useEvent(() => {
    setUpgradeModalOpened(false);
    setUpgradeModalProps(undefined);
  });

  const value = useMemo(
    () => ({
      credits,
      packages,
      updateCredits,
      upgradeModalProps: {
        isOpen: upgradeModalOpened,
        ...upgradeModalProps,
      },
      openUpgradeModal,
      closeUpgradeModal,
    }),
    [credits, packages, updateCredits, upgradeModalOpened, upgradeModalProps, openUpgradeModal, closeUpgradeModal],
  );

  return <CreditsContext.Provider value={value}>{children}</CreditsContext.Provider>;
}
