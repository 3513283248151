/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AddCloudinaryIntegrationRequest,
  Asset,
  BadRequestResponse,
  CloudinaryCloudListing,
  CloudinaryNamedTransformationResponse,
  CloudinaryOrganizationListing,
  CloudinaryUploadResponse,
  CreateCloudinaryTransformationRequest,
  DeleteCloudinaryIntegrationRequest,
  DeleteCloudinaryTransformationRequest,
  ExportAssetToCloudinaryRequest,
  FindOneAssetByCloudinaryPublicIdRequest,
  IntegrationsAck,
  Organization,
  SearchCloudinaryAssetsRequest,
  SearchCloudinaryAssetsResponse,
  SetCloudinaryActiveCloudRequest,
  SetupCloudinaryOrganizationRequest,
  UpdateCloudinaryIntegrationRequest,
  UpdateDamIntegrationSettingsRequest,
  VerifyCloudinaryIntegrationRequest,
} from '../models';
import {
  AddCloudinaryIntegrationRequestFromJSON,
  AddCloudinaryIntegrationRequestToJSON,
  AssetFromJSON,
  AssetToJSON,
  BadRequestResponseFromJSON,
  BadRequestResponseToJSON,
  CloudinaryCloudListingFromJSON,
  CloudinaryCloudListingToJSON,
  CloudinaryNamedTransformationResponseFromJSON,
  CloudinaryNamedTransformationResponseToJSON,
  CloudinaryOrganizationListingFromJSON,
  CloudinaryOrganizationListingToJSON,
  CloudinaryUploadResponseFromJSON,
  CloudinaryUploadResponseToJSON,
  CreateCloudinaryTransformationRequestFromJSON,
  CreateCloudinaryTransformationRequestToJSON,
  DeleteCloudinaryIntegrationRequestFromJSON,
  DeleteCloudinaryIntegrationRequestToJSON,
  DeleteCloudinaryTransformationRequestFromJSON,
  DeleteCloudinaryTransformationRequestToJSON,
  ExportAssetToCloudinaryRequestFromJSON,
  ExportAssetToCloudinaryRequestToJSON,
  FindOneAssetByCloudinaryPublicIdRequestFromJSON,
  FindOneAssetByCloudinaryPublicIdRequestToJSON,
  IntegrationsAckFromJSON,
  IntegrationsAckToJSON,
  OrganizationFromJSON,
  OrganizationToJSON,
  SearchCloudinaryAssetsRequestFromJSON,
  SearchCloudinaryAssetsRequestToJSON,
  SearchCloudinaryAssetsResponseFromJSON,
  SearchCloudinaryAssetsResponseToJSON,
  SetCloudinaryActiveCloudRequestFromJSON,
  SetCloudinaryActiveCloudRequestToJSON,
  SetupCloudinaryOrganizationRequestFromJSON,
  SetupCloudinaryOrganizationRequestToJSON,
  UpdateCloudinaryIntegrationRequestFromJSON,
  UpdateCloudinaryIntegrationRequestToJSON,
  UpdateDamIntegrationSettingsRequestFromJSON,
  UpdateDamIntegrationSettingsRequestToJSON,
  VerifyCloudinaryIntegrationRequestFromJSON,
  VerifyCloudinaryIntegrationRequestToJSON,
} from '../models';

export interface IntegrationsApiAddCloudinaryIntegrationOperationRequest {
  add_cloudinary_integration_request?: AddCloudinaryIntegrationRequest;
}

export interface IntegrationsApiCreateCloudinaryTransformationOperationRequest {
  create_cloudinary_transformation_request?: CreateCloudinaryTransformationRequest;
}

export interface IntegrationsApiDeleteCloudinaryIntegrationOperationRequest {
  delete_cloudinary_integration_request?: DeleteCloudinaryIntegrationRequest;
}

export interface IntegrationsApiDeleteCloudinaryTransformationOperationRequest {
  delete_cloudinary_transformation_request?: DeleteCloudinaryTransformationRequest;
}

export interface IntegrationsApiExportAssetToCloudinaryOperationRequest {
  export_asset_to_cloudinary_request?: ExportAssetToCloudinaryRequest;
}

export interface IntegrationsApiExportBlobToCloudinaryRequest {
  image: Blob;
  cloud_name: string;
  cld_public_id: string;
  cld_related_id?: string;
  cld_display_name?: string;
}

export interface IntegrationsApiFindOneAssetByCloudinaryPublicIdOperationRequest {
  find_one_asset_by_cloudinary_public_id_request?: FindOneAssetByCloudinaryPublicIdRequest;
}

export interface IntegrationsApiSearchCloudinaryAssetsOperationRequest {
  search_cloudinary_assets_request?: SearchCloudinaryAssetsRequest;
}

export interface IntegrationsApiSetCloudinaryActiveCloudOperationRequest {
  set_cloudinary_active_cloud_request?: SetCloudinaryActiveCloudRequest;
}

export interface IntegrationsApiSetupCloudinaryOrganizationOperationRequest {
  setup_cloudinary_organization_request?: SetupCloudinaryOrganizationRequest;
}

export interface IntegrationsApiUpdateCloudinaryIntegrationOperationRequest {
  update_cloudinary_integration_request?: UpdateCloudinaryIntegrationRequest;
}

export interface IntegrationsApiUpdateDamIntegrationSettingsOperationRequest {
  update_dam_integration_settings_request?: UpdateDamIntegrationSettingsRequest;
}

export interface IntegrationsApiVerifyCloudinaryIntegrationOperationRequest {
  verify_cloudinary_integration_request?: VerifyCloudinaryIntegrationRequest;
}

/**
 *
 */
export class IntegrationsApi extends runtime.BaseAPI {
  /**
   */
  async addCloudinaryIntegrationRaw(
    requestParameters: IntegrationsApiAddCloudinaryIntegrationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Organization>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/add-cloudinary-integration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AddCloudinaryIntegrationRequestToJSON(requestParameters.add_cloudinary_integration_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
  }

  /**
   */
  async addCloudinaryIntegration(
    add_cloudinary_integration_request?: AddCloudinaryIntegrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Organization> {
    const response = await this.addCloudinaryIntegrationRaw({ add_cloudinary_integration_request: add_cloudinary_integration_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async createCloudinaryTransformationRaw(
    requestParameters: IntegrationsApiCreateCloudinaryTransformationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryNamedTransformationResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/create-cloudinary-transformation`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateCloudinaryTransformationRequestToJSON(requestParameters.create_cloudinary_transformation_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryNamedTransformationResponseFromJSON(jsonValue));
  }

  /**
   */
  async createCloudinaryTransformation(
    create_cloudinary_transformation_request?: CreateCloudinaryTransformationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryNamedTransformationResponse> {
    const response = await this.createCloudinaryTransformationRaw(
      { create_cloudinary_transformation_request: create_cloudinary_transformation_request },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async deleteCloudinaryIntegrationRaw(
    requestParameters: IntegrationsApiDeleteCloudinaryIntegrationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Organization>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/delete-cloudinary-integration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteCloudinaryIntegrationRequestToJSON(requestParameters.delete_cloudinary_integration_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
  }

  /**
   */
  async deleteCloudinaryIntegration(
    delete_cloudinary_integration_request?: DeleteCloudinaryIntegrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Organization> {
    const response = await this.deleteCloudinaryIntegrationRaw({ delete_cloudinary_integration_request: delete_cloudinary_integration_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async deleteCloudinaryTransformationRaw(
    requestParameters: IntegrationsApiDeleteCloudinaryTransformationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryNamedTransformationResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/delete-cloudinary-transformation`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteCloudinaryTransformationRequestToJSON(requestParameters.delete_cloudinary_transformation_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryNamedTransformationResponseFromJSON(jsonValue));
  }

  /**
   */
  async deleteCloudinaryTransformation(
    delete_cloudinary_transformation_request?: DeleteCloudinaryTransformationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryNamedTransformationResponse> {
    const response = await this.deleteCloudinaryTransformationRaw(
      { delete_cloudinary_transformation_request: delete_cloudinary_transformation_request },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async exportAssetToCloudinaryRaw(
    requestParameters: IntegrationsApiExportAssetToCloudinaryOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryUploadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/export-asset-to-cloudinary`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ExportAssetToCloudinaryRequestToJSON(requestParameters.export_asset_to_cloudinary_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryUploadResponseFromJSON(jsonValue));
  }

  /**
   */
  async exportAssetToCloudinary(
    export_asset_to_cloudinary_request?: ExportAssetToCloudinaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryUploadResponse> {
    const response = await this.exportAssetToCloudinaryRaw({ export_asset_to_cloudinary_request: export_asset_to_cloudinary_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async exportBlobToCloudinaryRaw(
    requestParameters: IntegrationsApiExportBlobToCloudinaryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryUploadResponse>> {
    if (requestParameters.image === null || requestParameters.image === undefined) {
      throw new runtime.RequiredError('image', 'Required parameter requestParameters.image was null or undefined when calling exportBlobToCloudinary.');
    }

    if (requestParameters.cloud_name === null || requestParameters.cloud_name === undefined) {
      throw new runtime.RequiredError(
        'cloud_name',
        'Required parameter requestParameters.cloud_name was null or undefined when calling exportBlobToCloudinary.',
      );
    }

    if (requestParameters.cld_public_id === null || requestParameters.cld_public_id === undefined) {
      throw new runtime.RequiredError(
        'cld_public_id',
        'Required parameter requestParameters.cld_public_id was null or undefined when calling exportBlobToCloudinary.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append('image', requestParameters.image as any);
    }

    if (requestParameters.cld_related_id !== undefined) {
      formParams.append('cld_related_id', requestParameters.cld_related_id as any);
    }

    if (requestParameters.cld_display_name !== undefined) {
      formParams.append('cld_display_name', requestParameters.cld_display_name as any);
    }

    if (requestParameters.cloud_name !== undefined) {
      formParams.append('cloud_name', requestParameters.cloud_name as any);
    }

    if (requestParameters.cld_public_id !== undefined) {
      formParams.append('cld_public_id', requestParameters.cld_public_id as any);
    }

    const response = await this.request(
      {
        path: `/integrations/cloudinary/export-blob-to-cloudinary`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryUploadResponseFromJSON(jsonValue));
  }

  /**
   */
  async exportBlobToCloudinary(
    image: Blob,
    cloud_name: string,
    cld_public_id: string,
    cld_related_id?: string,
    cld_display_name?: string,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CloudinaryUploadResponse> {
    const response = await this.exportBlobToCloudinaryRaw(
      { image: image, cloud_name: cloud_name, cld_public_id: cld_public_id, cld_related_id: cld_related_id, cld_display_name: cld_display_name },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async findOneAssetByCloudinaryPublicIdRaw(
    requestParameters: IntegrationsApiFindOneAssetByCloudinaryPublicIdOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Asset>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/find-one-asset-by-cloudinary-public-id`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FindOneAssetByCloudinaryPublicIdRequestToJSON(requestParameters.find_one_asset_by_cloudinary_public_id_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetFromJSON(jsonValue));
  }

  /**
   */
  async findOneAssetByCloudinaryPublicId(
    find_one_asset_by_cloudinary_public_id_request?: FindOneAssetByCloudinaryPublicIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Asset> {
    const response = await this.findOneAssetByCloudinaryPublicIdRaw(
      { find_one_asset_by_cloudinary_public_id_request: find_one_asset_by_cloudinary_public_id_request },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async listCloudinaryCloudsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloudinaryCloudListing>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/list-clouds`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryCloudListingFromJSON(jsonValue));
  }

  /**
   */
  async listCloudinaryClouds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloudinaryCloudListing> {
    const response = await this.listCloudinaryCloudsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async listCloudinaryOrganizationsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CloudinaryOrganizationListing>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/list-organizations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => CloudinaryOrganizationListingFromJSON(jsonValue));
  }

  /**
   */
  async listCloudinaryOrganizations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloudinaryOrganizationListing> {
    const response = await this.listCloudinaryOrganizationsRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async searchCloudinaryAssetsRaw(
    requestParameters: IntegrationsApiSearchCloudinaryAssetsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SearchCloudinaryAssetsResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/searchCloudinaryAssets`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SearchCloudinaryAssetsRequestToJSON(requestParameters.search_cloudinary_assets_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SearchCloudinaryAssetsResponseFromJSON(jsonValue));
  }

  /**
   */
  async searchCloudinaryAssets(
    search_cloudinary_assets_request?: SearchCloudinaryAssetsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SearchCloudinaryAssetsResponse> {
    const response = await this.searchCloudinaryAssetsRaw({ search_cloudinary_assets_request: search_cloudinary_assets_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async setCloudinaryActiveCloudRaw(
    requestParameters: IntegrationsApiSetCloudinaryActiveCloudOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<IntegrationsAck>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/set-active-cloud`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SetCloudinaryActiveCloudRequestToJSON(requestParameters.set_cloudinary_active_cloud_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsAckFromJSON(jsonValue));
  }

  /**
   */
  async setCloudinaryActiveCloud(
    set_cloudinary_active_cloud_request?: SetCloudinaryActiveCloudRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IntegrationsAck> {
    const response = await this.setCloudinaryActiveCloudRaw({ set_cloudinary_active_cloud_request: set_cloudinary_active_cloud_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async setupCloudinaryOrganizationRaw(
    requestParameters: IntegrationsApiSetupCloudinaryOrganizationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Organization>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/setup-cloudinary-organization`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SetupCloudinaryOrganizationRequestToJSON(requestParameters.setup_cloudinary_organization_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
  }

  /**
   */
  async setupCloudinaryOrganization(
    setup_cloudinary_organization_request?: SetupCloudinaryOrganizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Organization> {
    const response = await this.setupCloudinaryOrganizationRaw({ setup_cloudinary_organization_request: setup_cloudinary_organization_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateCloudinaryIntegrationRaw(
    requestParameters: IntegrationsApiUpdateCloudinaryIntegrationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Organization>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/update-cloudinary-integration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateCloudinaryIntegrationRequestToJSON(requestParameters.update_cloudinary_integration_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
  }

  /**
   */
  async updateCloudinaryIntegration(
    update_cloudinary_integration_request?: UpdateCloudinaryIntegrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Organization> {
    const response = await this.updateCloudinaryIntegrationRaw({ update_cloudinary_integration_request: update_cloudinary_integration_request }, initOverrides);
    return await response.value();
  }

  /**
   */
  async updateDamIntegrationSettingsRaw(
    requestParameters: IntegrationsApiUpdateDamIntegrationSettingsOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<IntegrationsAck>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/update-dam-integration-settings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDamIntegrationSettingsRequestToJSON(requestParameters.update_dam_integration_settings_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsAckFromJSON(jsonValue));
  }

  /**
   */
  async updateDamIntegrationSettings(
    update_dam_integration_settings_request?: UpdateDamIntegrationSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IntegrationsAck> {
    const response = await this.updateDamIntegrationSettingsRaw(
      { update_dam_integration_settings_request: update_dam_integration_settings_request },
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async verifyCloudinaryIntegrationRaw(
    requestParameters: IntegrationsApiVerifyCloudinaryIntegrationOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<boolean>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/integrations/cloudinary/verify-cloudinary-integration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: VerifyCloudinaryIntegrationRequestToJSON(requestParameters.verify_cloudinary_integration_request),
      },
      initOverrides,
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async verifyCloudinaryIntegration(
    verify_cloudinary_integration_request?: VerifyCloudinaryIntegrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<boolean> {
    const response = await this.verifyCloudinaryIntegrationRaw({ verify_cloudinary_integration_request: verify_cloudinary_integration_request }, initOverrides);
    return await response.value();
  }
}
