/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudinaryOrganizationInfo } from './CloudinaryOrganizationInfo';
import { CloudinaryOrganizationInfoFromJSON, CloudinaryOrganizationInfoFromJSONTyped, CloudinaryOrganizationInfoToJSON } from './CloudinaryOrganizationInfo';

/**
 *
 * @export
 * @interface OrganizationIntegrations
 */
export interface OrganizationIntegrations {
  /**
   *
   * @type {CloudinaryOrganizationInfo}
   * @memberof OrganizationIntegrations
   */
  cloudinary?: CloudinaryOrganizationInfo;
}

/**
 * Check if a given object implements the OrganizationIntegrations interface.
 */
export function instanceOfOrganizationIntegrations(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function OrganizationIntegrationsFromJSON(json: any): OrganizationIntegrations {
  return OrganizationIntegrationsFromJSONTyped(json, false);
}

export function OrganizationIntegrationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationIntegrations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cloudinary: !exists(json, 'cloudinary') ? undefined : CloudinaryOrganizationInfoFromJSON(json['cloudinary']),
  };
}

export function OrganizationIntegrationsToJSON(value?: OrganizationIntegrations | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cloudinary: CloudinaryOrganizationInfoToJSON(value.cloudinary),
  };
}
