/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TextExtract } from './TextExtract';
import { TextExtractFromJSON, TextExtractFromJSONTyped, TextExtractToJSON } from './TextExtract';

/**
 *
 * @export
 * @interface TextExtractResponse
 */
export interface TextExtractResponse {
  /**
   *
   * @type {Array<TextExtract>}
   * @memberof TextExtractResponse
   */
  text_extracts: Array<TextExtract>;
}

/**
 * Check if a given object implements the TextExtractResponse interface.
 */
export function instanceOfTextExtractResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'text_extracts' in value;

  return isInstance;
}

export function TextExtractResponseFromJSON(json: any): TextExtractResponse {
  return TextExtractResponseFromJSONTyped(json, false);
}

export function TextExtractResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextExtractResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    text_extracts: (json['text_extracts'] as Array<any>).map(TextExtractFromJSON),
  };
}

export function TextExtractResponseToJSON(value?: TextExtractResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    text_extracts: (value.text_extracts as Array<any>).map(TextExtractToJSON),
  };
}
