/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetVariants } from './AssetVariants';
import { AssetVariantsFromJSON, AssetVariantsFromJSONTyped, AssetVariantsToJSON } from './AssetVariants';
import type { FTElementPermissions } from './FTElementPermissions';
import { FTElementPermissionsFromJSON, FTElementPermissionsFromJSONTyped, FTElementPermissionsToJSON } from './FTElementPermissions';

/**
 *
 * @export
 * @interface FTElementMetadata
 */
export interface FTElementMetadata {
  /**
   *
   * @type {AssetVariants}
   * @memberof FTElementMetadata
   */
  variants?: AssetVariants;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  active_variant?: FTElementMetadataActiveVariantEnum;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  asset_id?: string;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  asset_origin?: FTElementMetadataAssetOriginEnum;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  template_id?: string;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  owner_id?: string;
  /**
   *
   * @type {FTElementPermissions}
   * @memberof FTElementMetadata
   */
  permissions: FTElementPermissions;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  vertical_anchor: FTElementMetadataVerticalAnchorEnum;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  vertical_scale: FTElementMetadataVerticalScaleEnum;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  horizontal_anchor: FTElementMetadataHorizontalAnchorEnum;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  horizontal_scale: FTElementMetadataHorizontalScaleEnum;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  external_id?: string;
  /**
   *
   * @type {string}
   * @memberof FTElementMetadata
   */
  cld_layer_transformation?: string;
}

/**
 * @export
 */
export const FTElementMetadataActiveVariantEnum = {
  Original: 'original',
  Transparent: 'transparent',
} as const;
export type FTElementMetadataActiveVariantEnum = (typeof FTElementMetadataActiveVariantEnum)[keyof typeof FTElementMetadataActiveVariantEnum];

/**
 * @export
 */
export const FTElementMetadataAssetOriginEnum = {
  GenerativePackshot: 'generative_packshot',
  UserProduct: 'user_product',
  GeneratedProp: 'generated_prop',
  TextToGraphicDesign: 'text_to_graphic_design',
  TextToImage: 'text_to_image',
  VirtualStaging: 'virtual_staging',
  RestyleImage: 'restyle_image',
  BlendTwoImages: 'blend_two_images',
  Inpaint: 'inpaint',
  StudioShot: 'studio_shot',
  HomeStaging: 'home_staging',
  Editor: 'editor',
  LoraInputImage: 'lora_input_image',
  LoraTextToImage: 'lora_text_to_image',
  UserImage: 'user_image',
  Template: 'template',
} as const;
export type FTElementMetadataAssetOriginEnum = (typeof FTElementMetadataAssetOriginEnum)[keyof typeof FTElementMetadataAssetOriginEnum];

/**
 * @export
 */
export const FTElementMetadataVerticalAnchorEnum = {
  Top: 'top',
  Center: 'center',
  Bottom: 'bottom',
  TopAndBottom: 'top_and_bottom',
  Scale: 'scale',
  Auto: 'auto',
} as const;
export type FTElementMetadataVerticalAnchorEnum = (typeof FTElementMetadataVerticalAnchorEnum)[keyof typeof FTElementMetadataVerticalAnchorEnum];

/**
 * @export
 */
export const FTElementMetadataVerticalScaleEnum = {
  Fit: 'fit',
  Fill: 'fill',
} as const;
export type FTElementMetadataVerticalScaleEnum = (typeof FTElementMetadataVerticalScaleEnum)[keyof typeof FTElementMetadataVerticalScaleEnum];

/**
 * @export
 */
export const FTElementMetadataHorizontalAnchorEnum = {
  Left: 'left',
  Center: 'center',
  Right: 'right',
  LeftAndRight: 'left_and_right',
  Scale: 'scale',
  Auto: 'auto',
} as const;
export type FTElementMetadataHorizontalAnchorEnum = (typeof FTElementMetadataHorizontalAnchorEnum)[keyof typeof FTElementMetadataHorizontalAnchorEnum];

/**
 * @export
 */
export const FTElementMetadataHorizontalScaleEnum = {
  Fit: 'fit',
  Fill: 'fill',
} as const;
export type FTElementMetadataHorizontalScaleEnum = (typeof FTElementMetadataHorizontalScaleEnum)[keyof typeof FTElementMetadataHorizontalScaleEnum];

/**
 * Check if a given object implements the FTElementMetadata interface.
 */
export function instanceOfFTElementMetadata(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'permissions' in value;
  isInstance = isInstance && 'vertical_anchor' in value;
  isInstance = isInstance && 'vertical_scale' in value;
  isInstance = isInstance && 'horizontal_anchor' in value;
  isInstance = isInstance && 'horizontal_scale' in value;

  return isInstance;
}

export function FTElementMetadataFromJSON(json: any): FTElementMetadata {
  return FTElementMetadataFromJSONTyped(json, false);
}

export function FTElementMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FTElementMetadata {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    variants: !exists(json, 'variants') ? undefined : AssetVariantsFromJSON(json['variants']),
    active_variant: !exists(json, 'active_variant') ? undefined : json['active_variant'],
    asset_id: !exists(json, 'asset_id') ? undefined : json['asset_id'],
    asset_origin: !exists(json, 'asset_origin') ? undefined : json['asset_origin'],
    template_id: !exists(json, 'template_id') ? undefined : json['template_id'],
    owner_id: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    permissions: FTElementPermissionsFromJSON(json['permissions']),
    vertical_anchor: json['vertical_anchor'],
    vertical_scale: json['vertical_scale'],
    horizontal_anchor: json['horizontal_anchor'],
    horizontal_scale: json['horizontal_scale'],
    external_id: !exists(json, 'external_id') ? undefined : json['external_id'],
    cld_layer_transformation: !exists(json, 'cld_layer_transformation') ? undefined : json['cld_layer_transformation'],
  };
}

export function FTElementMetadataToJSON(value?: FTElementMetadata | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    variants: AssetVariantsToJSON(value.variants),
    active_variant: value.active_variant,
    asset_id: value.asset_id,
    asset_origin: value.asset_origin,
    template_id: value.template_id,
    owner_id: value.owner_id,
    permissions: FTElementPermissionsToJSON(value.permissions),
    vertical_anchor: value.vertical_anchor,
    vertical_scale: value.vertical_scale,
    horizontal_anchor: value.horizontal_anchor,
    horizontal_scale: value.horizontal_scale,
    external_id: value.external_id,
    cld_layer_transformation: value.cld_layer_transformation,
  };
}
