/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Organization } from './Organization';
import { OrganizationFromJSON, OrganizationFromJSONTyped, OrganizationToJSON } from './Organization';

/**
 *
 * @export
 * @interface CloudinaryOrganizationListing
 */
export interface CloudinaryOrganizationListing {
  /**
   *
   * @type {Array<Organization>}
   * @memberof CloudinaryOrganizationListing
   */
  items: Array<Organization>;
}

/**
 * Check if a given object implements the CloudinaryOrganizationListing interface.
 */
export function instanceOfCloudinaryOrganizationListing(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'items' in value;

  return isInstance;
}

export function CloudinaryOrganizationListingFromJSON(json: any): CloudinaryOrganizationListing {
  return CloudinaryOrganizationListingFromJSONTyped(json, false);
}

export function CloudinaryOrganizationListingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryOrganizationListing {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(OrganizationFromJSON),
  };
}

export function CloudinaryOrganizationListingToJSON(value?: CloudinaryOrganizationListing | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(OrganizationToJSON),
  };
}
