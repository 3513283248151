/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BrandFontsInner } from './BrandFontsInner';
import { BrandFontsInnerFromJSON, BrandFontsInnerFromJSONTyped, BrandFontsInnerToJSON } from './BrandFontsInner';
import type { BrandPalettesInner } from './BrandPalettesInner';
import { BrandPalettesInnerFromJSON, BrandPalettesInnerFromJSONTyped, BrandPalettesInnerToJSON } from './BrandPalettesInner';

/**
 *
 * @export
 * @interface Brand
 */
export interface Brand {
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof Brand
   */
  organization_id: string;
  /**
   *
   * @type {Date}
   * @memberof Brand
   */
  created_at: Date;
  /**
   *
   * @type {Date}
   * @memberof Brand
   */
  updated_at: Date;
  /**
   *
   * @type {Array<string>}
   * @memberof Brand
   */
  logo_asset_ids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Brand
   */
  photo_asset_ids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Brand
   */
  icon_asset_ids: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof Brand
   */
  graphic_asset_ids: Array<string>;
  /**
   *
   * @type {Array<BrandPalettesInner>}
   * @memberof Brand
   */
  palettes: Array<BrandPalettesInner>;
  /**
   *
   * @type {Array<BrandFontsInner>}
   * @memberof Brand
   */
  fonts: Array<BrandFontsInner>;
}

/**
 * Check if a given object implements the Brand interface.
 */
export function instanceOfBrand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'user_id' in value;
  isInstance = isInstance && 'organization_id' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'updated_at' in value;
  isInstance = isInstance && 'logo_asset_ids' in value;
  isInstance = isInstance && 'photo_asset_ids' in value;
  isInstance = isInstance && 'icon_asset_ids' in value;
  isInstance = isInstance && 'graphic_asset_ids' in value;
  isInstance = isInstance && 'palettes' in value;
  isInstance = isInstance && 'fonts' in value;

  return isInstance;
}

export function BrandFromJSON(json: any): Brand {
  return BrandFromJSONTyped(json, false);
}

export function BrandFromJSONTyped(json: any, ignoreDiscriminator: boolean): Brand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    user_id: json['user_id'],
    organization_id: json['organization_id'],
    created_at: new Date(json['created_at']),
    updated_at: new Date(json['updated_at']),
    logo_asset_ids: json['logo_asset_ids'],
    photo_asset_ids: json['photo_asset_ids'],
    icon_asset_ids: json['icon_asset_ids'],
    graphic_asset_ids: json['graphic_asset_ids'],
    palettes: (json['palettes'] as Array<any>).map(BrandPalettesInnerFromJSON),
    fonts: (json['fonts'] as Array<any>).map(BrandFontsInnerFromJSON),
  };
}

export function BrandToJSON(value?: Brand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    user_id: value.user_id,
    organization_id: value.organization_id,
    created_at: value.created_at.toISOString(),
    updated_at: value.updated_at.toISOString(),
    logo_asset_ids: value.logo_asset_ids,
    photo_asset_ids: value.photo_asset_ids,
    icon_asset_ids: value.icon_asset_ids,
    graphic_asset_ids: value.graphic_asset_ids,
    palettes: (value.palettes as Array<any>).map(BrandPalettesInnerToJSON),
    fonts: (value.fonts as Array<any>).map(BrandFontsInnerToJSON),
  };
}
