/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetIntegrations } from './AssetIntegrations';
import { AssetIntegrationsFromJSON, AssetIntegrationsFromJSONTyped, AssetIntegrationsToJSON } from './AssetIntegrations';
import type { AssetVariants } from './AssetVariants';
import { AssetVariantsFromJSON, AssetVariantsFromJSONTyped, AssetVariantsToJSON } from './AssetVariants';
import type { FTWorksheet } from './FTWorksheet';
import { FTWorksheetFromJSON, FTWorksheetFromJSONTyped, FTWorksheetToJSON } from './FTWorksheet';

/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  original_url: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  transparent_url: string;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  custom_size: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  size: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  serial?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  height?: number;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  size_orientation: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Asset
   */
  cropped: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  origin: AssetOriginEnum;
  /**
   *
   * @type {FTWorksheet}
   * @memberof Asset
   */
  canvas?: FTWorksheet;
  /**
   *
   * @type {AssetVariants}
   * @memberof Asset
   */
  variants: AssetVariants;
  /**
   *
   * @type {AssetIntegrations}
   * @memberof Asset
   */
  integrations?: AssetIntegrations;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  sharing: AssetSharingEnum;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  permission: AssetPermissionEnum;
  /**
   *
   * @type {Date}
   * @memberof Asset
   */
  created_at: Date;
  /**
   *
   * @type {Date}
   * @memberof Asset
   */
  updated_at: Date;
}

/**
 * @export
 */
export const AssetOriginEnum = {
  GenerativePackshot: 'generative_packshot',
  UserProduct: 'user_product',
  GeneratedProp: 'generated_prop',
  TextToGraphicDesign: 'text_to_graphic_design',
  TextToImage: 'text_to_image',
  VirtualStaging: 'virtual_staging',
  RestyleImage: 'restyle_image',
  BlendTwoImages: 'blend_two_images',
  Inpaint: 'inpaint',
  StudioShot: 'studio_shot',
  HomeStaging: 'home_staging',
  Editor: 'editor',
  LoraInputImage: 'lora_input_image',
  LoraTextToImage: 'lora_text_to_image',
  UserImage: 'user_image',
  Template: 'template',
} as const;
export type AssetOriginEnum = (typeof AssetOriginEnum)[keyof typeof AssetOriginEnum];

/**
 * @export
 */
export const AssetSharingEnum = {
  Private: 'private',
  Public: 'public',
  Organization: 'organization',
} as const;
export type AssetSharingEnum = (typeof AssetSharingEnum)[keyof typeof AssetSharingEnum];

/**
 * @export
 */
export const AssetPermissionEnum = {
  View: 'view',
  Edit: 'edit',
  Owner: 'owner',
  Unknown: 'unknown',
} as const;
export type AssetPermissionEnum = (typeof AssetPermissionEnum)[keyof typeof AssetPermissionEnum];

/**
 * Check if a given object implements the Asset interface.
 */
export function instanceOfAsset(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'user_id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'description' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'original_url' in value;
  isInstance = isInstance && 'transparent_url' in value;
  isInstance = isInstance && 'custom_size' in value;
  isInstance = isInstance && 'size' in value;
  isInstance = isInstance && 'size_orientation' in value;
  isInstance = isInstance && 'cropped' in value;
  isInstance = isInstance && 'origin' in value;
  isInstance = isInstance && 'variants' in value;
  isInstance = isInstance && 'sharing' in value;
  isInstance = isInstance && 'permission' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'updated_at' in value;

  return isInstance;
}

export function AssetFromJSON(json: any): Asset {
  return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    user_id: json['user_id'],
    name: json['name'],
    description: json['description'],
    product_id: json['product_id'],
    original_url: json['original_url'],
    transparent_url: json['transparent_url'],
    custom_size: json['custom_size'],
    size: json['size'],
    serial: !exists(json, 'serial') ? undefined : json['serial'],
    width: !exists(json, 'width') ? undefined : json['width'],
    height: !exists(json, 'height') ? undefined : json['height'],
    size_orientation: json['size_orientation'],
    cropped: json['cropped'],
    origin: json['origin'],
    canvas: !exists(json, 'canvas') ? undefined : FTWorksheetFromJSON(json['canvas']),
    variants: AssetVariantsFromJSON(json['variants']),
    integrations: !exists(json, 'integrations') ? undefined : AssetIntegrationsFromJSON(json['integrations']),
    sharing: json['sharing'],
    permission: json['permission'],
    created_at: new Date(json['created_at']),
    updated_at: new Date(json['updated_at']),
  };
}

export function AssetToJSON(value?: Asset | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    user_id: value.user_id,
    name: value.name,
    description: value.description,
    product_id: value.product_id,
    original_url: value.original_url,
    transparent_url: value.transparent_url,
    custom_size: value.custom_size,
    size: value.size,
    serial: value.serial,
    width: value.width,
    height: value.height,
    size_orientation: value.size_orientation,
    cropped: value.cropped,
    origin: value.origin,
    canvas: FTWorksheetToJSON(value.canvas),
    variants: AssetVariantsToJSON(value.variants),
    integrations: AssetIntegrationsToJSON(value.integrations),
    sharing: value.sharing,
    permission: value.permission,
    created_at: value.created_at.toISOString(),
    updated_at: value.updated_at.toISOString(),
  };
}
