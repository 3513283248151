/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { PromptRefinementRequest, PromptRefinementResponse } from '../models';
import { PromptRefinementRequestFromJSON, PromptRefinementRequestToJSON, PromptRefinementResponseFromJSON, PromptRefinementResponseToJSON } from '../models';

export interface TextProcessingApiPromptRefinementOperationRequest {
  prompt_refinement_request?: PromptRefinementRequest;
}

/**
 *
 */
export class TextProcessingApi extends runtime.BaseAPI {
  /**
   */
  async promptRefinementRaw(
    requestParameters: TextProcessingApiPromptRefinementOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PromptRefinementResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('basicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/text-processing/prompt-refinement`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PromptRefinementRequestToJSON(requestParameters.prompt_refinement_request),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PromptRefinementResponseFromJSON(jsonValue));
  }

  /**
   */
  async promptRefinement(
    prompt_refinement_request?: PromptRefinementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PromptRefinementResponse> {
    const response = await this.promptRefinementRaw({ prompt_refinement_request: prompt_refinement_request }, initOverrides);
    return await response.value();
  }
}
