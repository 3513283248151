/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BrandFontsInner
 */
export interface BrandFontsInner {
  /**
   *
   * @type {string}
   * @memberof BrandFontsInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BrandFontsInner
   */
  filename?: string;
}

/**
 * Check if a given object implements the BrandFontsInner interface.
 */
export function instanceOfBrandFontsInner(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function BrandFontsInnerFromJSON(json: any): BrandFontsInner {
  return BrandFontsInnerFromJSONTyped(json, false);
}

export function BrandFontsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandFontsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    filename: !exists(json, 'filename') ? undefined : json['filename'],
  };
}

export function BrandFontsInnerToJSON(value?: BrandFontsInner | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    filename: value.filename,
  };
}
