/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Brand } from './Brand';
import { BrandFromJSON, BrandFromJSONTyped, BrandToJSON } from './Brand';

/**
 *
 * @export
 * @interface ListBrandsResponse
 */
export interface ListBrandsResponse {
  /**
   *
   * @type {Array<Brand>}
   * @memberof ListBrandsResponse
   */
  items: Array<Brand>;
}

/**
 * Check if a given object implements the ListBrandsResponse interface.
 */
export function instanceOfListBrandsResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'items' in value;

  return isInstance;
}

export function ListBrandsResponseFromJSON(json: any): ListBrandsResponse {
  return ListBrandsResponseFromJSONTyped(json, false);
}

export function ListBrandsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListBrandsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(BrandFromJSON),
  };
}

export function ListBrandsResponseToJSON(value?: ListBrandsResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(BrandToJSON),
  };
}
