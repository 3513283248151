/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PromptRefinementRequest
 */
export interface PromptRefinementRequest {
  /**
   *
   * @type {string}
   * @memberof PromptRefinementRequest
   */
  refinement_instructions?: string;
  /**
   *
   * @type {string}
   * @memberof PromptRefinementRequest
   */
  prompt: string;
}

/**
 * Check if a given object implements the PromptRefinementRequest interface.
 */
export function instanceOfPromptRefinementRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'prompt' in value;

  return isInstance;
}

export function PromptRefinementRequestFromJSON(json: any): PromptRefinementRequest {
  return PromptRefinementRequestFromJSONTyped(json, false);
}

export function PromptRefinementRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptRefinementRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    refinement_instructions: !exists(json, 'refinement_instructions') ? undefined : json['refinement_instructions'],
    prompt: json['prompt'],
  };
}

export function PromptRefinementRequestToJSON(value?: PromptRefinementRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    refinement_instructions: value.refinement_instructions,
    prompt: value.prompt,
  };
}
