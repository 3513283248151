/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VerifyCloudinaryIntegrationRequest
 */
export interface VerifyCloudinaryIntegrationRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyCloudinaryIntegrationRequest
   */
  organization_id: string;
  /**
   *
   * @type {string}
   * @memberof VerifyCloudinaryIntegrationRequest
   */
  cld_secret_key: string;
  /**
   *
   * @type {string}
   * @memberof VerifyCloudinaryIntegrationRequest
   */
  cld_api_key: string;
  /**
   *
   * @type {string}
   * @memberof VerifyCloudinaryIntegrationRequest
   */
  cld_cloud_name: string;
}

/**
 * Check if a given object implements the VerifyCloudinaryIntegrationRequest interface.
 */
export function instanceOfVerifyCloudinaryIntegrationRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'organization_id' in value;
  isInstance = isInstance && 'cld_secret_key' in value;
  isInstance = isInstance && 'cld_api_key' in value;
  isInstance = isInstance && 'cld_cloud_name' in value;

  return isInstance;
}

export function VerifyCloudinaryIntegrationRequestFromJSON(json: any): VerifyCloudinaryIntegrationRequest {
  return VerifyCloudinaryIntegrationRequestFromJSONTyped(json, false);
}

export function VerifyCloudinaryIntegrationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyCloudinaryIntegrationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    organization_id: json['organization_id'],
    cld_secret_key: json['cld_secret_key'],
    cld_api_key: json['cld_api_key'],
    cld_cloud_name: json['cld_cloud_name'],
  };
}

export function VerifyCloudinaryIntegrationRequestToJSON(value?: VerifyCloudinaryIntegrationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    organization_id: value.organization_id,
    cld_secret_key: value.cld_secret_key,
    cld_api_key: value.cld_api_key,
    cld_cloud_name: value.cld_cloud_name,
  };
}
