import { AppBar, CircularProgress, Container, Grid, Link, ListItemIcon, MenuItem, Stack, Toolbar } from '@mui/material';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import HoverMenu from 'material-ui-popup-state/HoverMenu';

import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LABS_LIST, { labLabel } from 'src/assets/data/labs';
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover';
import { OptionalFeatureGate, RenderingFeatureGate } from '../../auth/FeatureGate';
import { useSubscriber } from '../../utils/Topics';
import { CreditsCounter } from '../credits-counter/CreditsCounter';
import Iconify from '../iconify';

export default function DesktopHeader() {
  const [activeApiCount, setActiveApiCount] = useState(0); //API_CALL_COMPLETE-- API_CALL_IN_PROGRESS++

  useSubscriber('API_CALL_IN_PROGRESS', async (_topic, _message) => {
    setActiveApiCount((count) => {
      let result = count + 1;
      return result;
    });
  });

  useSubscriber('API_CALL_COMPLETE', async (_topic, _message) => {
    setActiveApiCount((count) => {
      let result = count - 1;
      return result;
    });
  });

  return (
    <AppBar position='relative' sx={{ backgroundColor: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Container disableGutters>
        <Toolbar>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Stack direction={'row'} spacing={2.5} mt={0.5}>
                <a href='/'>
                  <img
                    src='https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png'
                    width='22'
                    height='22'
                    alt='final touch'
                  />
                </a>

                <PopupState variant='popover'>
                  {(popupState) => (
                    <>
                      <RouterLink style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }} to='/' {...bindHover(popupState)}>
                        Photo Editor
                      </RouterLink>
                    </>
                  )}
                </PopupState>

                <PopupState variant='popover'>
                  {(popupState) => (
                    <>
                      <RouterLink
                        style={{ color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }}
                        to='/#labs'
                        {...bindHover(popupState)}
                      >
                        Labs
                        <HoverMenu {...bindMenu(popupState)}>
                          {LABS_LIST.map((lab) => {
                            const link = (
                              <Link color='black' key={lab.navigateUrl} href={lab.navigateUrl} underline='none'>
                                <SubmenuItem key={lab.navigateUrl} icon={lab.icon} label={lab.name} />
                              </Link>
                            );

                            return (
                              <OptionalFeatureGate key={lab.id} flag={lab.featureFlag} value='true'>
                                {link}
                              </OptionalFeatureGate>
                            );
                          })}
                        </HoverMenu>
                      </RouterLink>
                    </>
                  )}
                </PopupState>

                <PopupState variant='popover'>
                  {(popupState) => (
                    <>
                      <RouterLink
                        style={{ display: 'none', color: 'black', fontSize: '12px', paddingTop: '3px', textDecoration: 'none' }}
                        to='/#halloween'
                        {...bindHover(popupState)}
                      >
                        Halloween
                        <HoverMenu {...bindMenu(popupState)}>
                          <Link color='black' href='/labs/holiday-styling' underline='none'>
                            <SubmenuItem icon='mdi:ghost' label='Photo Styling' />
                          </Link>
                          <Link color='black' href='/labs/holiday-studio-shot' underline='none'>
                            <SubmenuItem icon='mdi:camera' label='Studio Photoshoot' />
                          </Link>
                          <Link color='black' href='/labs/holiday-text-to-graphic-design' underline='none'>
                            <SubmenuItem icon='mingcute:pumpkin-lantern-fill' label={labLabel('text-to-graphic-design', 'name')} />
                          </Link>
                        </HoverMenu>
                      </RouterLink>
                    </>
                  )}
                </PopupState>
                <Link sx={{ color: 'black', fontSize: '12px', pt: '3px' }} href='https://final-tou.ch/faq/' target='_blank'>
                  Help
                </Link>
                <RenderingFeatureGate flag='santas_wonderland_enabled' value='true'>
                  <Link sx={{ color: '#d70b26', fontSize: '12px', pt: '3px' }} href='/labs/lora/'>
                    Wonderland
                  </Link>
                </RenderingFeatureGate>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction='row' spacing={2}>
                <CreditsCounter />

                <div style={{ marginTop: '-3px' }}>
                  <CircularProgress size={36} sx={{ position: 'absolute', margin: '-2px', display: activeApiCount > 0 ? 'block' : 'none' }} color='secondary' />
                  <AccountPopover />
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function SubmenuItem(props: { icon: string; label: string }) {
  return (
    <MenuItem
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{ fontSize: '12px' }}
    >
      <ListItemIcon>
        <Iconify icon={props.icon} width={16} />
      </ListItemIcon>
      {props.label}
    </MenuItem>
  );
}
