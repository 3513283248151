import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Dropzone from 'src/components/dropzone/Dropzone';
import { Asset, AssetOriginEnum, AssetPermissionEnum } from '../../generated/app_server_sdk';
import { useEvent } from '../../hooks/useEvent';
import appServer from '../../utils/appServer';
import { cldTransform, convertToPng, md5checksum } from '../../utils/imageUtils';
import { CircularProgressOverlay } from '../CircularProgressOverlay';
import { useCloudinaryMediaLibraryWidget } from '../cloudinary/MediaLibraryCloudinaryContext';
import { CloudinaryMediaLibraryAsset } from '../cloudinary/types';
import Iconify from '../iconify';
import { AssetFilterToolbar } from './AssetFilterToolbar';
import { OriginTypesEnum, originTypesEnum } from './AssetUtils';

interface AssetsDialogProps {
  onClose: () => void;
  onSelectAsset: (Asset: Asset) => void;
  asyncProcess?: boolean;
  enabledTabs?: Array<'products' | 'creations'>;
  uploadOriginType?: AssetOriginEnum;
}

const PAGE_SIZE = 10;

const DEFAULT_ITEM_IMAGE_URL = 'https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png';

const DEFAULT_ENABLED_TABS: Array<'products' | 'creations'> = ['products', 'creations'];

export function AssetPicker({
  onClose,
  onSelectAsset,
  asyncProcess = false,
  enabledTabs = DEFAULT_ENABLED_TABS,
  uploadOriginType = AssetOriginEnum.UserProduct,
}: AssetsDialogProps) {
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [urlMode, setUrlMode] = useState(false);
  const [uploadUrl, setUploadUrl] = useState('');
  const [availableAssets, setAvailableAssets] = useState<Asset[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isCreateAssetRequestInFlight, setIsCreateAssetRequestInFlight] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchResults, setSearchResults] = useState<Asset[] | undefined>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchRequestInFlight, setIsSearchRequestInFlight] = useState(false);

  const [originTypes, setOriginTypes] = useState<OriginTypesEnum[]>(enabledTabs.length > 0 ? [originTypesEnum.UserProduct] : [originTypesEnum.All]);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const listOriginTypes = originTypes.length === 1 && originTypes[0] === 'user_product' ? 'products' : 'creations';

  const cloudinaryMediaLibrary = useCloudinaryMediaLibraryWidget();

  const firstLoadInFlight = availableAssets.length === 0 && hasMore;

  const loadMoreAssets = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    let rawOriginTypes = originTypes.filter((v) => v !== originTypesEnum.All);
    if (rawOriginTypes.length === 0) {
      rawOriginTypes = Object.values(originTypesEnum).filter((v) => v !== originTypesEnum.UserProduct && v !== originTypesEnum.All);
    }
    const cursor = availableAssets.at(-1)?.serial;
    // not sure if type casting is the best way to handle this
    const resp = await appServer.assetsApi
      .listAssetsRaw({
        max_items: PAGE_SIZE,
        cursor_serial: cursor,
        origin: rawOriginTypes as AssetOriginEnum[],
      })
      .then((r) => r.value());

    setAvailableAssets(availableAssets.concat(resp.items));
    setHasMore(resp.items.length >= PAGE_SIZE);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(false);
    loadMoreAssets();
  }, [originTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  const onImageSelected = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      // browsers on macOS tend to misreport some image file types as png, so we force a conversion to png
      let image = acceptedFiles[0].type === 'image/png' ? await convertToPng(acceptedFiles[0]) : acceptedFiles[0];

      setIsCreateAssetRequestInFlight(true);
      try {
        const checksum = await md5checksum(image);
        let existingAsset = await appServer.assetsApi
          .findOneAssetBy({
            bytes: image.size,
            checksum,
            origin: uploadOriginType,
            permissions: [AssetPermissionEnum.Owner],
          })
          .catch(() => null);
        if (existingAsset) {
          onSelectAsset(existingAsset);
        } else {
          const newAsset = await appServer.assetsApi
            .createAssetRaw({
              image,
              origin: uploadOriginType,
              segment_foreground: uploadOriginType === AssetOriginEnum.UserProduct,
              analyze_content: uploadOriginType === AssetOriginEnum.UserProduct,
              async: asyncProcess,
            })
            .then((r) => r.value());
          onSelectAsset(newAsset);
        }

        onClose();
      } catch (e) {
        enqueueSnackbar('Something went wrong, please try again later', {
          key: 'CREATE_STICKER_FAILED',
          preventDuplicate: true,
          autoHideDuration: 5000,
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
      } finally {
        setIsCreateAssetRequestInFlight(false);
      }
    },
    [uploadOriginType, onClose, onSelectAsset, asyncProcess, enqueueSnackbar],
  );

  const searchAssets = useEvent(async (term: string) => {
    if (term === '') {
      setIsSearchRequestInFlight(false);
      setSearchResults(undefined);
      return;
    }
    setIsSearchRequestInFlight(true);

    try {
      const searchResult = await appServer.assetsApi.searchAssets(term);
      setSearchResults(searchResult.items);
    } catch (e) {
      enqueueSnackbar('Search failed, please try again later', {
        key: 'CREATE_STICKER_FAILED',
        preventDuplicate: true,
        autoHideDuration: 5000,
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
      setSearchResults([]);
    } finally {
      setIsSearchRequestInFlight(false);
    }
  });

  const debouncedSearchScenesRef = useRef(debounce(searchAssets, 500));

  useEffect(() => {
    debouncedSearchScenesRef.current(searchTerm);
  }, [searchTerm]);

  function forceSearch() {
    const debouncedSearchFn = debouncedSearchScenesRef.current;
    debouncedSearchFn.cancel();
    debouncedSearchFn(searchTerm);
    debouncedSearchFn.flush();
  }

  const fetchFromUrl = useEvent(async (url: string) => {
    try {
      setIsCreateAssetRequestInFlight(true);
      const response = await fetch(url, { mode: 'cors' });
      const blob = await response.blob();
      const file = new File([blob], url);
      return file;
    } catch (e) {
      enqueueSnackbar('Failed to fetch image from URL', {
        key: 'FETCH_IMAGE_FAILED',
        preventDuplicate: true,
        autoHideDuration: 5000,
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
    } finally {
      setIsCreateAssetRequestInFlight(false);
    }
  });

  const onCloudinaryInsert = useCallback(
    async (assets: CloudinaryMediaLibraryAsset[]) => {
      const asset = assets[0];
      const urlToFetch = asset.secure_url;
      const file = await fetchFromUrl(urlToFetch);
      if (!file) {
        return false;
      }

      await onImageSelected([file]);

      return true;
    },
    [onImageSelected, fetchFromUrl],
  );

  const openCloudinaryMediaLibrary = () => {
    cloudinaryMediaLibrary.showMediaLibrary({}, onCloudinaryInsert);
  };

  async function uploadFromUrl(url: string): Promise<void> {
    const urlPattern = new RegExp('^(https?)://.+');
    if (!urlPattern.test(url)) {
      enqueueSnackbar('Invalid URL', {
        key: 'INVALID_URL',
        preventDuplicate: true,
        autoHideDuration: 5000,
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
      });
      return;
    }
    const file = await fetchFromUrl(url);
    if (!file) {
      return;
    }
    onImageSelected([file]);
  }

  const handleOriginChange = useEvent((event: React.ChangeEvent<{ value: unknown }>, originTypes) => {
    setAvailableAssets([]);
    setHasMore(true);
    setOriginTypes(originTypes);
  });

  return (
    <Dialog open fullWidth maxWidth='md' scroll='paper' PaperProps={{ sx: { height: 'min(620px, 80vh)' } }}>
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{ pr: 7, background: theme.palette.background.default, width: '100%', zIndex: 1, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)' }}
      >
        <DialogTitle sx={{ p: 1, pt: enabledTabs.length > 0 ? 1 : 4 }}>
          {enabledTabs.length > 0 && (
            <Tabs
              variant='fullWidth'
              value={listOriginTypes}
              onChange={(e, newValue) => {
                setAvailableAssets([]);
                setHasMore(true);
                if (newValue === 'creations') {
                  setOriginTypes([originTypesEnum.All]);
                }
                if (newValue === 'products') {
                  setOriginTypes([AssetOriginEnum.UserProduct]);
                }
                setUrlMode(false);
                setSearchTerm('');
              }}
            >
              {enabledTabs.includes('products') && <Tab value={'products'} label='Recent Uploads' sx={{ width: '12rem' }} />}
              {enabledTabs.includes('creations') && <Tab value={'creations'} label='Recent Creations' sx={{ width: '12rem' }} />}
            </Tabs>
          )}
        </DialogTitle>
      </Stack>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          zIndex: 99,
        }}
      >
        <Iconify icon='material-symbols:close' />
      </IconButton>
      <DialogContent sx={{ px: 0 }} dividers ref={dialogContentRef}>
        {isCreateAssetRequestInFlight && <CircularProgressOverlay label='Saving...' />}
        <Dropzone onDrop={onImageSelected} noClick multiple={false} accept={{ 'image/png': [], 'image/jpeg': [], 'image/webp': [] }}>
          {({ getRootProps, getInputProps, open }) => (
            <Box
              {...getRootProps()}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
              }}
            >
              <input {...getInputProps()} />
              <Box
                flexDirection='row'
                justifyContent='center'
                alignItems='enter'
                height='3.5rem'
                position='sticky'
                top='0px'
                zIndex={1}
                boxShadow='0px 4px 10px rgba(0, 0, 0, 0.15)'
                minHeight='3.5rem'
                sx={{ background: 'white' }}
              >
                <Fade in={enabledTabs.length === 0 || listOriginTypes === 'products'} mountOnEnter unmountOnExit>
                  <Stack
                    spacing={2}
                    alignItems='center'
                    direction={'row'}
                    sx={{
                      position: 'absolute',
                      p: 1,
                      height: '3rem',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ whiteSpace: 'nowrap', flex: 2 }}>
                      <Button
                        variant='text'
                        color='muted'
                        onClick={() => {
                          setUrlMode(false);
                          open();
                        }}
                        startIcon={<Iconify icon='material-symbols:upload' />}
                        sx={{ px: 2, whiteSpace: 'nowrap' }}
                      >
                        Upload File
                      </Button>
                      <Button
                        variant='text'
                        color='muted'
                        onClick={() => {
                          setUrlMode(false);
                          openCloudinaryMediaLibrary();
                        }}
                        startIcon={<Iconify icon='logos:cloudinary-icon' />}
                        sx={{ px: 2, whiteSpace: 'nowrap' }}
                      >
                        Cloudinary
                      </Button>
                      <Button
                        variant='text'
                        color={urlMode ? 'primary' : 'muted'}
                        onClick={() => {
                          setUploadUrl('');
                          setUrlMode(!urlMode);
                        }}
                        startIcon={<Iconify icon='material-symbols:link-rounded' />}
                        sx={{ px: 2, minWidth: '4rem' }}
                      >
                        URL
                      </Button>
                    </Box>
                    <TextField
                      sx={{ justifySelf: 'flex-end', width: '220px' }}
                      size='small'
                      value={searchTerm}
                      onChange={(e) => {
                        setIsSearchRequestInFlight(true);
                        setSearchTerm(e.target.value);
                      }}
                      placeholder='Search...'
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          forceSearch();
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                          </InputAdornment>
                        ),
                        endAdornment: isSearchRequestInFlight && (
                          <InputAdornment position='end'>
                            <CircularProgress size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Fade>
                <Fade in={enabledTabs.length > 0 && listOriginTypes === 'creations'} mountOnEnter unmountOnExit>
                  <Box
                    sx={{
                      width: '100%',
                      position: 'absolute',
                      zIndex: 1,
                      background: 'white',
                      p: 1,
                    }}
                  >
                    <AssetFilterToolbar
                      filterName={searchTerm}
                      filterOrigin={originTypes[0]}
                      optionsOrigin={[...Object.values(originTypesEnum)]}
                      onFilterName={(e) => {
                        setIsSearchRequestInFlight(true);
                        setSearchTerm(e.target.value);
                      }}
                      onFilterOrigin={handleOriginChange}
                      isSearching={isSearchRequestInFlight}
                    />
                  </Box>
                </Fade>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  pl: 1,
                  pt: 2,
                  height: '100%',
                }}
              >
                {!firstLoadInFlight && (
                  <InfiniteScroll
                    key={listOriginTypes}
                    loader={
                      <Stack alignItems='center' sx={{ p: 4 }}>
                        {isLoading && <CircularProgress />}
                      </Stack>
                    }
                    hasMore={hasMore}
                    loadMore={loadMoreAssets}
                    height={400}
                  >
                    <Grid container key='container' spacing={2} sx={{ pb: 4 }}>
                      {(searchResults || availableAssets).map((asset) => (
                        <Grid item key={asset.id} md={3} sm={3}>
                          <AssetCard
                            asset={asset}
                            onSelect={() => {
                              onSelectAsset(asset);
                              onClose();
                            }}
                          />
                        </Grid>
                      ))}
                      {!hasMore && !isLoading && ((searchResults && searchResults.length === 0) || !availableAssets.length) && (
                        <Grid item md={12} sm={12} sx={{ alignText: 'center', justifyItems: 'center' }} key={'empty'}>
                          <Iconify icon='fluent:slide-search-32-regular' width={64} sx={{ mt: 14 }} />
                          <Typography variant='body2' fontSize='18px'>
                            Oops, nothing to see here!
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </InfiniteScroll>
                )}
                <Slide direction='down' in={urlMode} mountOnEnter unmountOnExit>
                  <Box
                    sx={{
                      mt: 1,
                      height: '100%',
                      p: 2,
                      position: 'absolute',
                      top: '0px',
                      left: '0px',
                      width: '100%',
                      background: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant='body2'>Public URL of file to upload:</Typography>
                    <TextField
                      fullWidth
                      autoFocus
                      value={uploadUrl}
                      onChange={(e) => setUploadUrl(e.target.value)}
                      sx={{ mt: 2 }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          uploadFromUrl(uploadUrl);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => {
                                uploadFromUrl(uploadUrl);
                              }}
                            >
                              <Iconify icon='material-symbols:upload' />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Slide>
              </Box>
            </Box>
          )}
        </Dropzone>
      </DialogContent>
      <DialogActions
        sx={{
          position: 'relative',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            marginTop: '-8px',
            width: '100%',
            height: '8px',
            background: 'linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.1))',
          },
        }}
      >
        <Button autoFocus onClick={urlMode ? () => setUrlMode(false) : onClose} sx={{ zIndex: 2 }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AssetCard(props: { asset: Asset; onSelect: () => void }) {
  const imgUrl = cldTransform(props.asset.original_url || DEFAULT_ITEM_IMAGE_URL, { w: 400, h: 400, c: 'pad' });

  return (
    <Card
      onClick={props.onSelect}
      sx={{
        cursor: 'pointer',
        border: '1px solid transparent',
        transition: 'all 0.15s ease-in-out',
        '&:hover': {
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
          transform: 'scale(1.03)',
        },
        aspectRatio: '1/1',
      }}
    >
      <img src={imgUrl} alt='' />
    </Card>
  );
}
