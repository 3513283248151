/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CloneAssetRequest
 */
export interface CloneAssetRequest {
  /**
   *
   * @type {string}
   * @memberof CloneAssetRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CloneAssetRequest
   */
  id: string;
}

/**
 * Check if a given object implements the CloneAssetRequest interface.
 */
export function instanceOfCloneAssetRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;

  return isInstance;
}

export function CloneAssetRequestFromJSON(json: any): CloneAssetRequest {
  return CloneAssetRequestFromJSONTyped(json, false);
}

export function CloneAssetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloneAssetRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    id: json['id'],
  };
}

export function CloneAssetRequestToJSON(value?: CloneAssetRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    id: value.id,
  };
}
