import { Helmet } from 'react-helmet-async';
// sections
import { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { m } from 'framer-motion';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import { useNavigate } from 'react-router';
import { setSession, storedAccessToken } from 'src/auth/utils';
import { MotionContainer, varBounce } from 'src/components/animate';
import { useApi } from 'src/components/api/useApi';
import analytics from 'src/utils/analytics';
import useAsyncEffect from 'use-async-effect';
import Login from '../../sections/auth/Login';

// ----------------------------------------------------------------------

export default function LoginPage() {
  let navigate = useNavigate();
  const api = useApi();

  let [blocked, setBlocked] = useState(false);

  let [blockedAnimation, setBlockedAnimation] = useState();
  useAsyncEffect(async () => {
    let url = new URL(window.location.href);
    let token = url.searchParams.get('token') || storedAccessToken();
    if (token) {
      setSession(token);
      const [, user] = await api.wrapResult(() => api.finaltouch.utilsApi.identity(), { showNotification: false });
      if (user) {
        analytics.track({ event_name: 'logged_in' });
        if (user.is_new) {
          analytics.track({ event_name: 'signed_up' });
        }
        analytics.flushBufferIfNeeded(true);
        setTimeout(() => {
          navigate('/');
          navigate(0);
        }, 100);
      }
    } else {
      const [, response] = await api.wrapResult(() => api.finaltouch.utilsApi.walledgarden(), { showNotification: false });
      if (response?.blocked) {
        setBlocked(response.blocked);
        fetch('https://res.cloudinary.com/ft-bounty/raw/upload/v1692693925/app-materials/blocked.json').then(async (resp) => {
          let animation = await resp.json();
          setBlockedAnimation(animation);
        });
      }
    }

    // store token
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Login | Final Touch</title>
      </Helmet>
      {blocked ? (
        <Grid container spacing={0} direction='column' alignItems='center' justifyContent='center' sx={{ minHeight: '100vh' }}>
          <Grid item xs={3} p={3}>
            <MotionContainer>
              <m.div variants={varBounce().in}>
                <img src='https://res.cloudinary.com/ft-bounty/image/upload/w_48,w_48/v1684407931/app-materials/logo-icon.png' alt='' />
              </m.div>
            </MotionContainer>
          </Grid>
          <Grid item xs={3} p={2}>
            <MotionContainer>
              <Typography variant='h3' paragraph textAlign={'justify'}>
                Final touch can't be open from within an app. please open the link in your web browser
              </Typography>

              <div>
                <Typography variant='h3' textAlign={'center'} color={'primary'}>
                  <input
                    type='text'
                    value={window.location.href}
                    style={{
                      outline: 'none',
                      border: '0px black',
                      width: '100%',
                      fontSize: '24px',
                      fontFamily: 'Raleway, sans-serif',
                      color: '#e11374',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                    onClick={(e) => {
                      e.currentTarget.select();
                    }}
                    onChange={() => {}}
                  />
                </Typography>
              </div>
              {blockedAnimation && <Lottie loop={false} animationData={blockedAnimation} play style={{ width: 240, height: 240 }} />}
            </MotionContainer>
          </Grid>
        </Grid>
      ) : (
        <Login />
      )}
    </>
  );
}
