/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CleanArtifactsRequest } from './CleanArtifactsRequest';
import { CleanArtifactsRequestFromJSON, CleanArtifactsRequestFromJSONTyped, CleanArtifactsRequestToJSON } from './CleanArtifactsRequest';
import type { RenderingRequestParamsClonePhoto } from './RenderingRequestParamsClonePhoto';
import {
  RenderingRequestParamsClonePhotoFromJSON,
  RenderingRequestParamsClonePhotoFromJSONTyped,
  RenderingRequestParamsClonePhotoToJSON,
} from './RenderingRequestParamsClonePhoto';
import type { RenderingRequestParamsNewPhoto } from './RenderingRequestParamsNewPhoto';
import {
  RenderingRequestParamsNewPhotoFromJSON,
  RenderingRequestParamsNewPhotoFromJSONTyped,
  RenderingRequestParamsNewPhotoToJSON,
} from './RenderingRequestParamsNewPhoto';

/**
 *
 * @export
 * @interface RenderingRequestParams
 */
export interface RenderingRequestParams {
  /**
   *
   * @type {string}
   * @memberof RenderingRequestParams
   */
  type: RenderingRequestParamsTypeEnum;
  /**
   *
   * @type {RenderingRequestParamsClonePhoto}
   * @memberof RenderingRequestParams
   */
  clone_photo?: RenderingRequestParamsClonePhoto;
  /**
   *
   * @type {CleanArtifactsRequest}
   * @memberof RenderingRequestParams
   */
  existing_photo?: CleanArtifactsRequest;
  /**
   *
   * @type {RenderingRequestParamsNewPhoto}
   * @memberof RenderingRequestParams
   */
  new_photo?: RenderingRequestParamsNewPhoto;
}

/**
 * @export
 */
export const RenderingRequestParamsTypeEnum = {
  New: 'new',
  Existing: 'existing',
  Clone: 'clone',
} as const;
export type RenderingRequestParamsTypeEnum = (typeof RenderingRequestParamsTypeEnum)[keyof typeof RenderingRequestParamsTypeEnum];

/**
 * Check if a given object implements the RenderingRequestParams interface.
 */
export function instanceOfRenderingRequestParams(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'type' in value;

  return isInstance;
}

export function RenderingRequestParamsFromJSON(json: any): RenderingRequestParams {
  return RenderingRequestParamsFromJSONTyped(json, false);
}

export function RenderingRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenderingRequestParams {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json['type'],
    clone_photo: !exists(json, 'clone_photo') ? undefined : RenderingRequestParamsClonePhotoFromJSON(json['clone_photo']),
    existing_photo: !exists(json, 'existing_photo') ? undefined : CleanArtifactsRequestFromJSON(json['existing_photo']),
    new_photo: !exists(json, 'new_photo') ? undefined : RenderingRequestParamsNewPhotoFromJSON(json['new_photo']),
  };
}

export function RenderingRequestParamsToJSON(value?: RenderingRequestParams | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    clone_photo: RenderingRequestParamsClonePhotoToJSON(value.clone_photo),
    existing_photo: CleanArtifactsRequestToJSON(value.existing_photo),
    new_photo: RenderingRequestParamsNewPhotoToJSON(value.new_photo),
  };
}
