/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PackageFeatures
 */
export interface PackageFeatures {
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  lora_training_and_usage?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  asset_collaboration?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  editor_custom_canvas_sizes?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  editor_responsive_resize?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  brand_kit_limit?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  editor_assets_limit?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  cloudinary_dam_integration?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  cloudinary_pm_integration?: string;
  /**
   *
   * @type {string}
   * @memberof PackageFeatures
   */
  editor_custom_font?: string;
}

/**
 * Check if a given object implements the PackageFeatures interface.
 */
export function instanceOfPackageFeatures(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PackageFeaturesFromJSON(json: any): PackageFeatures {
  return PackageFeaturesFromJSONTyped(json, false);
}

export function PackageFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackageFeatures {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    lora_training_and_usage: !exists(json, 'lora_training_and_usage') ? undefined : json['lora_training_and_usage'],
    asset_collaboration: !exists(json, 'asset_collaboration') ? undefined : json['asset_collaboration'],
    editor_custom_canvas_sizes: !exists(json, 'editor_custom_canvas_sizes') ? undefined : json['editor_custom_canvas_sizes'],
    editor_responsive_resize: !exists(json, 'editor_responsive_resize') ? undefined : json['editor_responsive_resize'],
    brand_kit_limit: !exists(json, 'brand_kit_limit') ? undefined : json['brand_kit_limit'],
    editor_assets_limit: !exists(json, 'editor_assets_limit') ? undefined : json['editor_assets_limit'],
    cloudinary_dam_integration: !exists(json, 'cloudinary_dam_integration') ? undefined : json['cloudinary_dam_integration'],
    cloudinary_pm_integration: !exists(json, 'cloudinary_pm_integration') ? undefined : json['cloudinary_pm_integration'],
    editor_custom_font: !exists(json, 'editor_custom_font') ? undefined : json['editor_custom_font'],
  };
}

export function PackageFeaturesToJSON(value?: PackageFeatures | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    lora_training_and_usage: value.lora_training_and_usage,
    asset_collaboration: value.asset_collaboration,
    editor_custom_canvas_sizes: value.editor_custom_canvas_sizes,
    editor_responsive_resize: value.editor_responsive_resize,
    brand_kit_limit: value.brand_kit_limit,
    editor_assets_limit: value.editor_assets_limit,
    cloudinary_dam_integration: value.cloudinary_dam_integration,
    cloudinary_pm_integration: value.cloudinary_pm_integration,
    editor_custom_font: value.editor_custom_font,
  };
}
