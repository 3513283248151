import { Box, Button, Dialog, DialogContent, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { ReactNode } from 'react';

import { useIntercom } from 'react-use-intercom';
import { useAuthContext } from 'src/auth/AuthContext';
import { useCreditsContext } from '../credits-context/CreditsContext';
import Iconify from '../iconify';

export function CreditsCounter() {
  const { credits, openUpgradeModal } = useCreditsContext();

  if (!credits) {
    return <></>;
  }

  return (
    <>
      <Tooltip
        title={
          <>
            {credits.remaining} out of {credits.cap} AI Credits remaining until {format(parseISO(credits.until), 'MMM do')}.
          </>
        }
      >
        <Button
          onClick={() => openUpgradeModal()}
          variant='text'
          color='secondary'
          size='small'
          startIcon={<Iconify icon='wi:stars' width={16} />}
          sx={{ color: 'secondary.darker' }}
        >
          {credits.remaining}/{credits.cap}
        </Button>
      </Tooltip>

      <PaywallDialog />
    </>
  );
}

function PaywallDialog() {
  const { upgradeModalProps, closeUpgradeModal } = useCreditsContext();
  const { user } = useAuthContext();

  const { showNewMessage } = useIntercom();

  const onContactUs = (planName: string) => {
    showNewMessage(`Hi there! I'm interested in upgrading my account to your ${planName} plan`);
    closeUpgradeModal();
  };

  const plan = (user?.plan_id ?? 'free') as 'free' | 'designer' | 'team';

  return (
    <Dialog sx={{ minWidth: 1100 }} fullWidth open={upgradeModalProps.isOpen} maxWidth='lg' onClose={closeUpgradeModal}>
      <DialogContent sx={{ px: 7, py: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'end', mr: -2, mt: 1 }}>
          <IconButton onClick={closeUpgradeModal}>
            <Iconify icon='material-symbols:close' width={16} />
          </IconButton>
        </Box>
        <Stack direction='column' spacing={7}>
          <Stack direction='column' spacing={1} alignItems='center'>
            <Typography variant='h6' sx={{ fontWeight: 500, fontSize: ['20px'], color: '#848199', fontFamily: 'Raleway' }}>
              For this feature you'll need to
            </Typography>
            <Typography variant='h4' sx={{ fontWeight: 800, fontSize: ['40px'], color: '#231D4F', fontFamily: 'Raleway' }}>
              Upgrade your plan
            </Typography>
          </Stack>

          <Box
            sx={{
              backgroundColor: '#f5f5fa',
              borderRadius: '26px',
              p: 3,
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(292px, 1fr))',
            }}
          >
            <PlanCard
              price={0}
              name='Free'
              description='Discover AI image generation with our platform and advanced AI tools'
              isCurrent={plan === 'free'}
              features={[
                <>
                  80 AI credits <Iconify icon='wi:stars' width={16} />
                </>,
                'AI tools',
                'Community templates',
                'Up to 10 Assets',
              ]}
              isHighlighted={false}
            />
            <PlanCard
              badgeText='Most popular'
              price={100}
              name='Pro'
              description='Perfect for users who need extra credits for higher usage'
              isCurrent={plan === 'designer'}
              features={[
                <>
                  150 AI credits <Iconify icon='wi:stars' width={16} />
                </>,
                'AI tools',
                'Premium templates',
                'Up to 50 Assets',
                'Responsive resizing',
                'Custom fonts',
              ]}
              isHighlighted={upgradeModalProps.highlightedPlan === 'designer'}
              onContactUs={() => onContactUs('Designer')}
            />
            <PlanCard
              price={200}
              name='Team'
              description='Perfect for teams striving to achieve seamless collaboration'
              isCurrent={plan === 'team'}
              features={[
                <>
                  150 AI credits <Iconify icon='wi:stars' width={16} />
                </>,
                'AI tools',
                'Premium templates',
                'Up to 50 Assets',
                'Responsive resizing',
                'Custom fonts',
                'Team sharing templates & files',
                'First to access labs features',
              ]}
              isHighlighted={upgradeModalProps.highlightedPlan === 'team'}
              onContactUs={() => onContactUs('Team')}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

interface PlanCardProps {
  badgeText?: string;
  price: number;
  name: string;
  isCurrent: boolean;
  description: string;
  features: ReactNode[];
  isHighlighted: boolean;
  onContactUs?: () => void;
}

function PlanCard({ badgeText, price, name, isCurrent, description, features, isHighlighted, onContactUs }: PlanCardProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          pb: 3,
          px: 4,
          fontFamily: 'Raleway',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: 440,
          gap: 2,
          borderRadius: '26px',
          width: '100%',
          backgroundColor: isHighlighted ? '#5243C2' : 'transparent',
          color: isHighlighted ? 'white' : '#231D4F',
          boxShadow: isHighlighted ? '0px 42px 34px 0px #5243C24B' : 'none',
          position: isHighlighted ? 'absolute' : 'static',
          height: isHighlighted ? '115%' : '100%',
          pt: isHighlighted ? 5 : 3,
          bottom: 0,
        }}
      >
        {badgeText && (
          <Box
            sx={{
              position: 'absolute',
              textTransform: 'uppercase',
              backgroundColor: isHighlighted ? '#4031B3' : '#EDEDED',
              color: isHighlighted ? 'white' : '#231D4F',
              borderRadius: '13.5px',
              p: 1,
              fontSize: '10px',
              fontWeight: 800,
              top: isHighlighted ? 12 : 0,
              right: 12,
            }}
          >
            {badgeText}
          </Box>
        )}
        <Stack direction='column' spacing={2}>
          {/* commented out in case we want to present price at some point */}
          {/*<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>*/}
          {/*  <Box sx={{ fontSize: 40, letterSpacing: 0 }}>${price}</Box>*/}
          {/*  {price > 0 && <Box sx={{ display: 'inline-block', fontSize: 16, fontWeight: 500, mt: 0.5 }}>/month</Box>}*/}
          {/*</Box>*/}
          <Box sx={{ fontWeight: 800, fontSize: 28 }}>
            {name} {price > 0 && <Iconify icon='lucide:crown' width={24} sx={{ verticalAlign: 'middle', marginBottom: '4px' }} />}{' '}
            {isCurrent && <Box sx={{ display: 'inline-block', fontSize: 16, color: isHighlighted ? 'white' : '#848199' }}>(current plan)</Box>}
          </Box>
          <Box>{description}</Box>
          <Stack direction='column' spacing={1}>
            {features.map((feature, index) => (
              <Box key={index} display='flex' alignItems='center' gap={1}>
                <Iconify icon='duo-icons:check-circle' width={20} sx={{ color: isHighlighted ? 'white' : '#5243C2' }} />
                {feature}
              </Box>
            ))}
          </Stack>
        </Stack>
        {onContactUs && (
          <Button
            variant='contained'
            color='primary'
            onClick={onContactUs}
            sx={{
              backgroundColor: isHighlighted ? '#FF62AC' : '#FF5FAA1A',
              color: isHighlighted ? 'white' : '#FF3393',
              '&:hover': {
                backgroundColor: isHighlighted ? undefined : '#FF5FAAAA',
              },
            }}
          >
            Contact us
          </Button>
        )}
      </Box>
    </Box>
  );
}
