import { rgbToHex } from '@mui/material';
import md5 from 'md5';
import Pako from 'pako';
import { createLogger } from './Logger';
import ProcessedFile from './ProcessedFile';
import { untilTrue } from './timers';

export const EDITOR_SUPPORTED_MIMETYPES = {
  'image/avif': ['.avif'],
  'image/gif': ['.gif'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/svg+xml': ['.svg'],
  'image/webp': ['.webp'],
};

export const IMPORT_SUPPORTED_MIMETYPES = {
  'image/vnd.adobe.photoshop': ['.psd'],
  'application/x-photoshop': ['.psd'],
  'application/octet-stream': ['.psd'],
};
interface ISegmentation {
  width: number;
  height: number;
  rle: Array<Array<number>>;
}
function compress(input: Record<string, any> | string, type: string | undefined = undefined): Blob {
  if (!(input instanceof String)) {
    input = JSON.stringify(input);
  }
  let enc = new TextEncoder();
  let compressed = Pako.deflate(enc.encode(input.toString()));
  const blob = new Blob([compressed], { type: type });
  return blob as Blob;
}
function rleToBitmap(segmentation: ISegmentation): Array<Array<number>> {
  let segments = segmentation.rle[0];
  let sequences = segmentation.rle[1];
  let bitmap = Array(segmentation.height).fill(Array(segmentation.width));
  let row = 0;
  let column = 0;
  // let start = Date.now()
  sequences.forEach((sequence: number, segmentIndex: number) => {
    for (let i = 0; i < sequence; i++) {
      bitmap[row][column] = segments[segmentIndex];
      column += 1;
      if (column % segmentation.width === 0) {
        row += 1;
        column = 0;
      }
    }
  });
  return bitmap;
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(String(reader.result));
    reader.readAsDataURL(blob);
  });
}

export function isLayeredFile(file: File) {
  const extensions = Object.values(IMPORT_SUPPORTED_MIMETYPES).flat();
  const mimetypes = Object.keys(IMPORT_SUPPORTED_MIMETYPES);

  if (mimetypes.includes(file.type)) {
    return true;
  }
  const fileExtension = file.name.toLowerCase().split('.').pop() || '';
  if (extensions.includes(`.${fileExtension}`)) {
    return true;
  }
  return false;
}
function canvasOptimizedTransform(url: string | undefined, canvas: { width: number; height: number }, manual_transformation?: string): string {
  // convert to png since its supported on all browsers
  return cldTransform(url, { c: 'limit', w: canvas.width, h: canvas.height, f: 'png' }, manual_transformation);
}

function cldTransform(url: string | undefined, transformation: Record<string, any> | Array<Record<string, any>>, manual_transformation?: string): string {
  const logger = createLogger();
  if (!url) {
    // empty gif
    return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  }
  if (!url.includes('cloudinary.com')) {
    if (!url.startsWith('data:')) {
      logger.debug(`transformation ${JSON.stringify(transformation)} requested on non cloudinary url ${url}`);
    }

    return url;
  }

  const parts = url.match(/(?<base>.*)\/v(?<version>\d+)\/(?<public_id>.*)/);

  if (!transformation.length) {
    transformation = [transformation];
  }
  let transformation_url = transformation
    .map((component: Record<string, any>) =>
      Object.keys(component)
        .map((key) => `${key}_${component[key]}`)
        .join(','),
    )
    .join('/');

  const transformations = [transformation_url, manual_transformation].filter((t) => !!t);
  const paddedTransformationUrl = transformations.length > 0 ? `${transformations.join('/')}/` : '';

  return `${parts?.groups?.base}/${paddedTransformationUrl}v${parts?.groups?.version}/${parts?.groups?.public_id}`;
}

async function cldExists(url: string): Promise<boolean> {
  try {
    let response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (_) {
    return false;
  }
}

export async function md5checksum(file: File) {
  // get byte array of file
  const buffer = await file.arrayBuffer();
  return md5(new Uint8Array(buffer));
}

export async function convertToPng(imageFile: File) {
  return new Promise<File>((resolve, reject) => {
    const image = new Image();
    const reader = new FileReader();
    image.setAttribute('crossorigin', 'anonymous');

    reader.onload = () => {
      image.src = reader.result as string;
      image.onload = () => {
        const canvas = new OffscreenCanvas(image.width, image.height);

        // getContext doesn't return a discriminated type based on the provided param, so I specify it myself
        const ctx = canvas.getContext('2d') as OffscreenCanvasRenderingContext2D;
        if (!ctx) {
          reject('Could not get 2d context from canvas');
          return;
        }
        ctx.drawImage(image, 0, 0);

        // for some reason the convertToBlob method is missing in 4.9.5 typescript;
        // it was present in 3.6 and later reappears in 5.0
        // https://github.com/microsoft/TypeScript/blob/v5.0-rc/src/lib/dom.generated.d.ts
        // @ts-ignore
        canvas.convertToBlob({ type: 'image/png' }).then((blob: Blob) => {
          resolve(new File([blob], imageFile.name, { type: 'image/png' }));
        });
      };
    };

    reader.readAsDataURL(imageFile);
  });
}

export function debugImage(imageData: ImageData) {
  if (!imageData) return;
  if (!imageData.width || !imageData.height) return;

  try {
    const canvas = new OffscreenCanvas(imageData.width, imageData.height);
    const ctx = canvas.getContext('2d');
    if (ctx) {
      // found this function on stack overflow;
      // not sure why the below lines glow red in TS, but they seem to work
      // doesn't matter cause this is a debug util
      // @ts-ignore
      ctx.putImageData(imageData, 0, 0);
      // @ts-ignore
      canvas.convertToBlob().then((blob) => {
        const reader = new FileReader();

        reader.onload = () => {
          const dataUri = reader.result;
          console.warn('dataUri', { blob, dataUri });

          const uniqueId = new Date().getTime();
          const style = `font-size: 300px; background: url("${dataUri}#${uniqueId}") no-repeat; background-size: contain;`;
          console.log('%c     ', style);
        };

        reader.onerror = (error) => {
          console.error('Error: ', error);
        };

        reader.readAsDataURL(blob);
      });
    }
  } catch (e) {
    console.error(e);
  }
}

export async function imageDataToBlob(imageData: ImageData): Promise<Blob> {
  const { width, height } = imageData;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  let ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error(`Could not get 2d context from canvas`);
  }
  ctx.putImageData(imageData, 0, 0, 0, 0, width, height);

  return new Promise((resolve, reject) => {
    try {
      // canvas.toBlob implied image/png format
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error(`Could not convert canvas to blob`));
        } else {
          resolve(blob);
        }
      });
    } catch (e) {
      reject(e);
    }
  });
}

export async function blobToImageData(blob: Blob): Promise<ImageData> {
  const url = URL.createObjectURL(blob); // create an Object URL
  const img = new Image(); // create a temp. image object
  img.setAttribute('crossorigin', 'anonymous');

  return new Promise((resolve) => {
    img.onload = function () {
      // handle async image loading
      URL.revokeObjectURL(url); // free memory held by Object URL
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      if (ctx) {
        c.width = img.width;
        c.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(ctx.getImageData(0, 0, img.width, img.height));
      }
    };

    img.src = url;
  });
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
}

export const shareImage = async (blob: Blob, filename: string) => {
  const file = new File([blob], filename, { type: blob.type });
  if (!navigator.share) return;
  await navigator.share({
    title: 'Final Touch AI',
    text: 'Check out this image generated using Final Touch AI',
    files: [file],
  });
};

export function proxyAvatarWithCloudinaryIfPossible(url?: string) {
  let result = url || '';
  if (result.startsWith('https://lh3.googleusercontent.com/')) {
    result = result.replace('https://lh3.googleusercontent.com/', 'https://res.cloudinary.com/ft-bounty/image/upload/v1/googleusercontent/');
  }

  return result;
}

export const getShotSize = (productSize: number) => {
  if (productSize < 10) {
    return 'extreme close up shot';
  } else if (productSize < 30) {
    return 'close up shot';
  } else if (productSize < 50) {
    return 'medium close up shot';
  } else if (productSize < 70) {
    return 'medium shot';
  } else if (productSize < 90) {
    return 'medium wide shot';
  } else if (productSize < 120) {
    return 'wide shot';
  } else {
    return 'extreme wide shot';
  }
};

export async function prefetch(url: string) {
  const img = new Image();
  img.setAttribute('crossorigin', 'anonymous');
  img.src = url;
  await new Promise<void>((resolve) => {
    img.onload = () => resolve();
  });
}

export const dataUriToBase64Image = (image: string) => ProcessedFile.fromUrl(image).then((file) => file.asBase64File());

export const BROKEN_IMAGE_URL = 'https://res.cloudinary.com/ft-bounty/image/upload/v1694704093/app-materials/broken_image.png';
export const LOGO_URL = 'https://res.cloudinary.com/ft-bounty/image/upload/v1684407931/app-materials/logo-icon.png';
export { blobToBase64, canvasOptimizedTransform, cldExists, cldTransform, compress, rleToBitmap };

export async function getFontSizeByWidth(fontFamily: string, width: number, height: number, text = 'M') {
  const span = document.createElement('span');
  span.style.fontFamily = fontFamily;
  span.style.fontSize = '16px';
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.top = '0';
  span.style.left = '0';
  span.style.whiteSpace = 'nowrap';
  span.style.zIndex = '-1';
  span.textContent = text;
  document.body.appendChild(span);
  await untilTrue(
    () => {
      try {
        document.fonts.check(`${fontFamily}`);
        return true;
      } catch {
        return false;
      }
    },
    { timeout: 3000, interval: 100 },
  );
  const spanWidth = span.offsetWidth * 1.05; // consider 5% padding
  const ratio = width / spanWidth;
  const fontSize = 16 * ratio;
  document.body.removeChild(span);
  return fontSize;
}

export function getAverageColorHexa(img: HTMLImageElement) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const bgColor = getBackgroundColor(imageData);

  let r = 0,
    g = 0,
    b = 0,
    count = 0;
  const { data } = imageData;
  for (let i = 0; i < data.length; i += 4) {
    const [r1, g1, b1] = [data[i], data[i + 1], data[i + 2]];
    if (Math.abs(r1 - bgColor.r) > 30 || Math.abs(g1 - bgColor.g) > 30 || Math.abs(b1 - bgColor.b) > 30) {
      r += r1;
      g += g1;
      b += b1;
      count++;
    }
  }

  r = Math.round(r / count);
  g = Math.round(g / count);
  b = Math.round(b / count);

  return rgbToHex(`rgb(${r}, ${g}, ${b})`);
}

export function getBackgroundColor(imageData: ImageData) {
  const [r, g, b] = Array.from(imageData.data.slice(0, 3));
  return { r, g, b };
}

export const resolveFetcherUrl = (userId: string, path: string) => {
  return `${process.env.REACT_APP_APP_SERVER_BASE_PATH}/images/fetch/${userId}/${path}`;
};

export function getFontMetrics(font: string, text: string) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Could not get 2d context from canvas');
  }
  ctx.font = font;

  const metrics = ctx.measureText(text);

  return {
    ascent: metrics.actualBoundingBoxAscent,
    descent: metrics.actualBoundingBoxDescent,
    height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
  };
}

export const calcTransparentPixelsPercentage = (imageData: ImageData) => {
  // ImageData.data - A Uint8ClampedArray representing a one-dimensional array containing the data in the RGBA order, with integer values between 0 and 255 (inclusive). The order goes by rows from the top-left pixel to the bottom-right.
  // The transparency data (alpha pixel) exists in every 4n element of the array.
  const { data } = imageData;
  let count = 0;
  for (let i = 3; i < data.length; i += 4) {
    if (data[i] === 0) {
      count++;
    }
  }
  const totalPixels = data.length / 4;
  return (count / totalPixels) * 100;
};
