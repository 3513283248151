/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface FindOneAssetByCloudinaryPublicIdRequest
 */
export interface FindOneAssetByCloudinaryPublicIdRequest {
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByCloudinaryPublicIdRequest
   */
  cld_cloud_name: string;
  /**
   *
   * @type {string}
   * @memberof FindOneAssetByCloudinaryPublicIdRequest
   */
  cld_public_id: string;
}

/**
 * Check if a given object implements the FindOneAssetByCloudinaryPublicIdRequest interface.
 */
export function instanceOfFindOneAssetByCloudinaryPublicIdRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cld_cloud_name' in value;
  isInstance = isInstance && 'cld_public_id' in value;

  return isInstance;
}

export function FindOneAssetByCloudinaryPublicIdRequestFromJSON(json: any): FindOneAssetByCloudinaryPublicIdRequest {
  return FindOneAssetByCloudinaryPublicIdRequestFromJSONTyped(json, false);
}

export function FindOneAssetByCloudinaryPublicIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOneAssetByCloudinaryPublicIdRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cld_cloud_name: json['cld_cloud_name'],
    cld_public_id: json['cld_public_id'],
  };
}

export function FindOneAssetByCloudinaryPublicIdRequestToJSON(value?: FindOneAssetByCloudinaryPublicIdRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cld_cloud_name: value.cld_cloud_name,
    cld_public_id: value.cld_public_id,
  };
}
