/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FontDeclaration } from './FontDeclaration';
import { FontDeclarationFromJSON, FontDeclarationFromJSONTyped, FontDeclarationToJSON } from './FontDeclaration';

/**
 *
 * @export
 * @interface CreateCloudinaryTransformationRequest
 */
export interface CreateCloudinaryTransformationRequest {
  /**
   *
   * @type {boolean}
   * @memberof CreateCloudinaryTransformationRequest
   */
  overwrite: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateCloudinaryTransformationRequest
   */
  ft_asset_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateCloudinaryTransformationRequest
   */
  cld_transformation_base_layer: string;
  /**
   *
   * @type {Array<FontDeclaration>}
   * @memberof CreateCloudinaryTransformationRequest
   */
  fonts?: Array<FontDeclaration>;
  /**
   *
   * @type {string}
   * @memberof CreateCloudinaryTransformationRequest
   */
  cloud_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateCloudinaryTransformationRequest
   */
  cld_transformation_directives: string;
  /**
   *
   * @type {string}
   * @memberof CreateCloudinaryTransformationRequest
   */
  cld_transformation_name: string;
}

/**
 * Check if a given object implements the CreateCloudinaryTransformationRequest interface.
 */
export function instanceOfCreateCloudinaryTransformationRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'overwrite' in value;
  isInstance = isInstance && 'cld_transformation_base_layer' in value;
  isInstance = isInstance && 'cloud_name' in value;
  isInstance = isInstance && 'cld_transformation_directives' in value;
  isInstance = isInstance && 'cld_transformation_name' in value;

  return isInstance;
}

export function CreateCloudinaryTransformationRequestFromJSON(json: any): CreateCloudinaryTransformationRequest {
  return CreateCloudinaryTransformationRequestFromJSONTyped(json, false);
}

export function CreateCloudinaryTransformationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCloudinaryTransformationRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    overwrite: json['overwrite'],
    ft_asset_id: !exists(json, 'ft_asset_id') ? undefined : json['ft_asset_id'],
    cld_transformation_base_layer: json['cld_transformation_base_layer'],
    fonts: !exists(json, 'fonts') ? undefined : (json['fonts'] as Array<any>).map(FontDeclarationFromJSON),
    cloud_name: json['cloud_name'],
    cld_transformation_directives: json['cld_transformation_directives'],
    cld_transformation_name: json['cld_transformation_name'],
  };
}

export function CreateCloudinaryTransformationRequestToJSON(value?: CreateCloudinaryTransformationRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    overwrite: value.overwrite,
    ft_asset_id: value.ft_asset_id,
    cld_transformation_base_layer: value.cld_transformation_base_layer,
    fonts: value.fonts === undefined ? undefined : (value.fonts as Array<any>).map(FontDeclarationToJSON),
    cloud_name: value.cloud_name,
    cld_transformation_directives: value.cld_transformation_directives,
    cld_transformation_name: value.cld_transformation_name,
  };
}
