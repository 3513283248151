/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateUserSettingsRequestPayload
 */
export interface UpdateUserSettingsRequestPayload {
  /**
   *
   * @type {string}
   * @memberof UpdateUserSettingsRequestPayload
   */
  current_organization_id?: string;
}

/**
 * Check if a given object implements the UpdateUserSettingsRequestPayload interface.
 */
export function instanceOfUpdateUserSettingsRequestPayload(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateUserSettingsRequestPayloadFromJSON(json: any): UpdateUserSettingsRequestPayload {
  return UpdateUserSettingsRequestPayloadFromJSONTyped(json, false);
}

export function UpdateUserSettingsRequestPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserSettingsRequestPayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    current_organization_id: !exists(json, 'current_organization_id') ? undefined : json['current_organization_id'],
  };
}

export function UpdateUserSettingsRequestPayloadToJSON(value?: UpdateUserSettingsRequestPayload | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    current_organization_id: value.current_organization_id,
  };
}
