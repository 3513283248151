/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudinaryCloudInfo } from './CloudinaryCloudInfo';
import { CloudinaryCloudInfoFromJSON, CloudinaryCloudInfoFromJSONTyped, CloudinaryCloudInfoToJSON } from './CloudinaryCloudInfo';

/**
 *
 * @export
 * @interface CloudinaryCloudListing
 */
export interface CloudinaryCloudListing {
  /**
   *
   * @type {Array<CloudinaryCloudInfo>}
   * @memberof CloudinaryCloudListing
   */
  items: Array<CloudinaryCloudInfo>;
}

/**
 * Check if a given object implements the CloudinaryCloudListing interface.
 */
export function instanceOfCloudinaryCloudListing(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'items' in value;

  return isInstance;
}

export function CloudinaryCloudListingFromJSON(json: any): CloudinaryCloudListing {
  return CloudinaryCloudListingFromJSONTyped(json, false);
}

export function CloudinaryCloudListingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryCloudListing {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    items: (json['items'] as Array<any>).map(CloudinaryCloudInfoFromJSON),
  };
}

export function CloudinaryCloudListingToJSON(value?: CloudinaryCloudListing | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    items: (value.items as Array<any>).map(CloudinaryCloudInfoToJSON),
  };
}
