/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudinaryCloudAsset } from './CloudinaryCloudAsset';
import { CloudinaryCloudAssetFromJSON, CloudinaryCloudAssetFromJSONTyped, CloudinaryCloudAssetToJSON } from './CloudinaryCloudAsset';

/**
 *
 * @export
 * @interface CloudinaryAssetInfo
 */
export interface CloudinaryAssetInfo {
  /**
   *
   * @type {Array<CloudinaryCloudAsset>}
   * @memberof CloudinaryAssetInfo
   */
  clouds?: Array<CloudinaryCloudAsset>;
}

/**
 * Check if a given object implements the CloudinaryAssetInfo interface.
 */
export function instanceOfCloudinaryAssetInfo(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function CloudinaryAssetInfoFromJSON(json: any): CloudinaryAssetInfo {
  return CloudinaryAssetInfoFromJSONTyped(json, false);
}

export function CloudinaryAssetInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryAssetInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    clouds: !exists(json, 'clouds') ? undefined : (json['clouds'] as Array<any>).map(CloudinaryCloudAssetFromJSON),
  };
}

export function CloudinaryAssetInfoToJSON(value?: CloudinaryAssetInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    clouds: value.clouds === undefined ? undefined : (value.clouds as Array<any>).map(CloudinaryCloudAssetToJSON),
  };
}
