/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ExportAssetToCloudinaryRequest
 */
export interface ExportAssetToCloudinaryRequest {
  /**
   *
   * @type {string}
   * @memberof ExportAssetToCloudinaryRequest
   */
  cld_related_id?: string;
  /**
   *
   * @type {string}
   * @memberof ExportAssetToCloudinaryRequest
   */
  cld_display_name?: string;
  /**
   *
   * @type {string}
   * @memberof ExportAssetToCloudinaryRequest
   */
  cld_public_id?: string;
  /**
   *
   * @type {string}
   * @memberof ExportAssetToCloudinaryRequest
   */
  cloud_name: string;
  /**
   *
   * @type {string}
   * @memberof ExportAssetToCloudinaryRequest
   */
  ft_asset_id: string;
}

/**
 * Check if a given object implements the ExportAssetToCloudinaryRequest interface.
 */
export function instanceOfExportAssetToCloudinaryRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cloud_name' in value;
  isInstance = isInstance && 'ft_asset_id' in value;

  return isInstance;
}

export function ExportAssetToCloudinaryRequestFromJSON(json: any): ExportAssetToCloudinaryRequest {
  return ExportAssetToCloudinaryRequestFromJSONTyped(json, false);
}

export function ExportAssetToCloudinaryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportAssetToCloudinaryRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cld_related_id: !exists(json, 'cld_related_id') ? undefined : json['cld_related_id'],
    cld_display_name: !exists(json, 'cld_display_name') ? undefined : json['cld_display_name'],
    cld_public_id: !exists(json, 'cld_public_id') ? undefined : json['cld_public_id'],
    cloud_name: json['cloud_name'],
    ft_asset_id: json['ft_asset_id'],
  };
}

export function ExportAssetToCloudinaryRequestToJSON(value?: ExportAssetToCloudinaryRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cld_related_id: value.cld_related_id,
    cld_display_name: value.cld_display_name,
    cld_public_id: value.cld_public_id,
    cloud_name: value.cloud_name,
    ft_asset_id: value.ft_asset_id,
  };
}
