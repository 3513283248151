/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizationIntegrations } from './OrganizationIntegrations';
import { OrganizationIntegrationsFromJSON, OrganizationIntegrationsFromJSONTyped, OrganizationIntegrationsToJSON } from './OrganizationIntegrations';

/**
 *
 * @export
 * @interface Organization
 */
export interface Organization {
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  user_membership_type: OrganizationUserMembershipTypeEnum;
  /**
   *
   * @type {OrganizationIntegrations}
   * @memberof Organization
   */
  integrations: OrganizationIntegrations;
}

/**
 * @export
 */
export const OrganizationUserMembershipTypeEnum = {
  Admin: 'admin',
  Member: 'member',
  Unknown: 'unknown',
} as const;
export type OrganizationUserMembershipTypeEnum = (typeof OrganizationUserMembershipTypeEnum)[keyof typeof OrganizationUserMembershipTypeEnum];

/**
 * Check if a given object implements the Organization interface.
 */
export function instanceOfOrganization(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'user_membership_type' in value;
  isInstance = isInstance && 'integrations' in value;

  return isInstance;
}

export function OrganizationFromJSON(json: any): Organization {
  return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    user_membership_type: json['user_membership_type'],
    integrations: OrganizationIntegrationsFromJSON(json['integrations']),
  };
}

export function OrganizationToJSON(value?: Organization | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    user_membership_type: value.user_membership_type,
    integrations: OrganizationIntegrationsToJSON(value.integrations),
  };
}
