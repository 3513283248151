/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PackageFeatures } from './PackageFeatures';
import { PackageFeaturesFromJSON, PackageFeaturesFromJSONTyped, PackageFeaturesToJSON } from './PackageFeatures';

/**
 *
 * @export
 * @interface FinalTouchPackage
 */
export interface FinalTouchPackage {
  /**
   *
   * @type {string}
   * @memberof FinalTouchPackage
   */
  name: string;
  /**
   *
   * @type {PackageFeatures}
   * @memberof FinalTouchPackage
   */
  features: PackageFeatures;
}

/**
 * Check if a given object implements the FinalTouchPackage interface.
 */
export function instanceOfFinalTouchPackage(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'features' in value;

  return isInstance;
}

export function FinalTouchPackageFromJSON(json: any): FinalTouchPackage {
  return FinalTouchPackageFromJSONTyped(json, false);
}

export function FinalTouchPackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinalTouchPackage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    features: PackageFeaturesFromJSON(json['features']),
  };
}

export function FinalTouchPackageToJSON(value?: FinalTouchPackage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    features: PackageFeaturesToJSON(value.features),
  };
}
