import { ErrorBoundary, Provider } from '@rollbar/react';

import { ConfirmProvider } from 'material-ui-confirm';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAuthContext } from './auth/AuthContext';
import { MotionLazyContainer } from './components/animate';
import SnackbarProvider from './components/snackbar';
import Router from './routes';
import ThemeProvider from './theme';
import analytics from './utils/analytics';

// ----------------------------------------------------------------------

interface StackFrame {
  colno: number;
  filename: string;
  lineno: number;
  method: string;
}

const rollbarCheckIgnore = (_isUncaught: any, _args: any, item: Record<string, any>) => {
  const frames: Array<StackFrame> = item?.body?.trace?.frames || [];
  const { origin } = window.location;
  const externalError = frames.findIndex((frame) => frame.filename.startsWith(origin)) === -1;
  if (externalError) {
    return true;
  }
  const message = JSON.stringify(item?.body);
  const ignoredMessages = [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'ChromeExtensionError',
    'node_modules/@openreplay',
  ];
  if (message && ignoredMessages.some((ignoredMessage) => message.includes(ignoredMessage))) {
    return true;
  }
  return false;
};
async function transformer(data: Record<string, any>, item: Record<string, any>): Promise<void> {
  data.custom.requestId = item?.err?.response?.headers?.get('x-request-id');
}
export default function App() {
  const { user } = useAuthContext();
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    environment: process.env.REACT_APP_ENV,
    captureUncaught: true,
    captureUnhandledRejections: true,
    transform: transformer,
    enabled: process.env.REACT_APP_ENV !== 'development',
    payload: {
      client: {
        javascript: {
          code_version: process.env.REACT_APP_VERSION,
          source_map_enabled: true,
        },
      },
      custom: {
        userId: user?.id,
        userEmail: user?.email,
      },
    },
    checkIgnore: rollbarCheckIgnore,
  };

  const location = useLocation();

  useEffect(() => {
    analytics.track({ event_name: 'page_load' });
  }, [location]);

  useEffect(() => {
    if (process.env.REACT_APP_HEAP_ENVIRONMENT_ID) {
      // this is not the heap variable that is defined in analytics
      // but the loader that is defined in index.html
      // heap overwrites the loader with the actual SDK so it might be a bit confusing
      // @ts-ignore
      heap.load(process.env.REACT_APP_HEAP_ENVIRONMENT_ID);
    }
  }, []);

  return (
    <MotionLazyContainer>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <ThemeProvider>
            <ConfirmProvider>
              <SnackbarProvider>
                <Router />
              </SnackbarProvider>
            </ConfirmProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </Provider>
    </MotionLazyContainer>
  );
}
