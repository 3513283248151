import { ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import trackerAssist from '@openreplay/tracker-assist';

import Tracker from '@openreplay/tracker';
import { User } from 'src/generated/app_server_sdk';
import { setUserLoggingOptions } from 'src/utils/Logger';
import { GlobalEventTracker } from 'src/utils/analytics';
import Login from '../pages/auth/LoginPage';

import { useRollbar } from '@rollbar/react';
import { useAuthContext } from './AuthContext';

let tracker: Tracker | undefined;
let eventTracker: GlobalEventTracker | undefined;
// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isAuthenticated } = useAuthContext();
  const rollbar = useRollbar();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isAuthenticated) {
    return <LoadingScreen />;
  }

  if (user?.status !== 'active') {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (user) {
    let appUser = user as User;
    if (rollbar?.configure) {
      rollbar.configure({
        payload: {
          custom: {
            userId: appUser.id,
            userEmail: appUser.email,
          },
        },
      });
    }

    if (appUser.settings.openreplay === '1' && !tracker) {
      let force_openreplay = process.env.REACT_APP_FORCE_OPENREPLAY === 'true';

      if (process.env.NODE_ENV !== 'development' || force_openreplay) {
        tracker = new Tracker({
          __DISABLE_SECURE_MODE: force_openreplay, // set REACT_APP_FORCE_OPENREPLAY=true in tour env only when debugging the tracker locally
          projectKey: 'eJ7CPsyQrFqDd4v60gVU',
          ingestPoint: 'https://openreplay.final-tou.ch/ingest',
          canvas: {
            disableCanvas: false,
          },
        });

        tracker.setUserID(appUser.email);
        tracker.use(trackerAssist({}));
        tracker.start();
      }
    }
    if (appUser.settings.enable_client_side_logging === '1') {
      setUserLoggingOptions({
        netOut: true,
        consoleOut: true,
      });
    }
    if (appUser.settings.global_usage_tracking === 'true') {
      if (!eventTracker) {
        eventTracker = new GlobalEventTracker();
        eventTracker.init();
      }
    }
  }

  return <>{children}</>;
}
