/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CloudinaryCloudInfo
 */
export interface CloudinaryCloudInfo {
  /**
   *
   * @type {string}
   * @memberof CloudinaryCloudInfo
   */
  cloud_name: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryCloudInfo
   */
  api_key: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryCloudInfo
   */
  api_secret: string;
  /**
   *
   * @type {string}
   * @memberof CloudinaryCloudInfo
   */
  external_id: string;
}

/**
 * Check if a given object implements the CloudinaryCloudInfo interface.
 */
export function instanceOfCloudinaryCloudInfo(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'cloud_name' in value;
  isInstance = isInstance && 'api_key' in value;
  isInstance = isInstance && 'api_secret' in value;
  isInstance = isInstance && 'external_id' in value;

  return isInstance;
}

export function CloudinaryCloudInfoFromJSON(json: any): CloudinaryCloudInfo {
  return CloudinaryCloudInfoFromJSONTyped(json, false);
}

export function CloudinaryCloudInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudinaryCloudInfo {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cloud_name: json['cloud_name'],
    api_key: json['api_key'],
    api_secret: json['api_secret'],
    external_id: json['external_id'],
  };
}

export function CloudinaryCloudInfoToJSON(value?: CloudinaryCloudInfo | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cloud_name: value.cloud_name,
    api_key: value.api_key,
    api_secret: value.api_secret,
    external_id: value.external_id,
  };
}
