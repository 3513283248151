import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { isMobile } from 'react-device-detect';
import { useIntercom } from 'react-use-intercom';
import { Organization, User } from 'src/generated/app_server_sdk';
import analytics from 'src/utils/analytics';
import appServer from 'src/utils/appServer';
import { useLogger } from 'src/utils/Logger';

import { useEvent } from 'src/hooks/useEvent';
import { isValidToken, setSession, storedAccessToken } from './utils';

// ----------------------------------------------------------------------

export const AuthContext = createContext<AuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};
const inIframe = () => {
  return window.self !== window.top;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const logger = useLogger();
  const authContextValue = useAuthContextValue();
  const { boot, trackEvent } = useIntercom();
  analytics.appendIntercomSink(trackEvent);
  const { login } = authContextValue;
  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? storedAccessToken() : '';
      const user = await login(accessToken);

      const skipIntercomBoot = inIframe() || isMobile;
      if (skipIntercomBoot) {
        logger.debug('do not display Intercom in iframe or mobile');
      } else {
        boot({
          email: user.email,
          userId: user.id,
        });
      }

      analytics.heap.identify(user.id);
      analytics.heap.addUserProperties({ id: user.id, email: user.email, name: user.name });
    } catch (_error) {}
  }, [boot, logger, login]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGOUT

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}

function useAuthContextValue() {
  const [user, setUser] = useState<User | null>(null);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const logout = useEvent(async () => {
    setSession(null);
    setUser(null);
    setOrganizations([]);
    setIsAuthenticated(true);
  });

  const login = useEvent(async (token: string | null) => {
    const missingOrInvalidToken = !token || !isValidToken(token);
    if (missingOrInvalidToken) {
      setSession(null);
      setUser(null);
      setIsAuthenticated(true);
      setOrganizations([]);
      throw new Error('invalid access token');
    }

    try {
      const user = await appServer.utilsApi.identity({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setSession(token);
      setUser(user);
      setIsAuthenticated(true);
      const organizations = await appServer.utilsApi.listOrganizations();
      setOrganizations(organizations.items);
      return user;
    } catch (e) {
      setUser(null);
      setIsAuthenticated(true);
      setOrganizations([]);
      throw e;
    }
  });

  const refreshUser = useEvent(async () => {
    try {
      const user = await appServer.utilsApi.identity();
      setUser(user);
      const organizations = await appServer.utilsApi.listOrganizations();
      setOrganizations(organizations.items);
    } catch (_) {
      // setUser(null);
      // setSession(null);
    }
  });

  const loginWithGoogle = useEvent(() => {
    let windowLocation = new URL(window.location.href);
    let url = `${process.env.REACT_APP_APP_SERVER_BASE_PATH}/auth/login/google?return_to=${encodeURI(window.location.href)}&with_token=1`;
    if (windowLocation.searchParams.has('pc')) {
      url += `&pc=${windowLocation.searchParams.get('pc')}`;
    }
    window.location.href = url;
  });

  const loginWithCloudinary = useEvent(() => {
    let windowLocation = new URL(window.location.href);
    let url = `${process.env.REACT_APP_APP_SERVER_BASE_PATH}/auth/login/cloudinary?return_to=${encodeURI(window.location.href)}&with_token=1`;
    if (windowLocation.searchParams.has('pc')) {
      url += `&pc=${windowLocation.searchParams.get('pc')}`;
    }
    window.location.href = url;
  });

  return {
    user,
    organizations,
    loginWithGoogle,
    loginWithCloudinary,
    isAuthenticated,
    refreshUser,
    logout,
    login,
  };
}

export type AuthContextType = {
  user: User | null;
  isAuthenticated: boolean;
  organizations: Organization[];
  login: (token: string) => Promise<User>;
  logout: () => Promise<void>;
  refreshUser: () => Promise<void>;
  loginWithGoogle?: () => void;
  loginWithCloudinary?: () => void;
};
