import { Typography } from '@mui/material';
import { useRollbar, ErrorBoundary } from '@rollbar/react';
import { useSnackbar } from 'notistack';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';

function ErrorFallback({ error, resetError }: { error: Error | null; resetError: () => void }) {
  const rollbar = useRollbar();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!error) return;

    const errorId = `eb-${nanoid(6)}`;
    let message = (
      <Typography variant='body1'>
        An error occured, please try again later.
        <br />
        <small>(Support Id: {errorId})</small>
      </Typography>
    );

    enqueueSnackbar(message, { variant: 'error' });

    rollbar.error(error, undefined, { custom: { errorId } });
    resetError();
  }, [error, enqueueSnackbar, rollbar, resetError]);

  return <></>;
}

export const ErrorBoundaryWrapper = ({ children }: { children: React.ReactNode }) => {
  return <ErrorBoundary fallbackUI={ErrorFallback}>{children}</ErrorBoundary>;
};
