/* tslint:disable */
/* eslint-disable */
/**
 * finaltouch-app-server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.2022608.app
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import { AssetFromJSON, AssetFromJSONTyped, AssetToJSON } from './Asset';
import type { BrandFontsInner } from './BrandFontsInner';
import { BrandFontsInnerFromJSON, BrandFontsInnerFromJSONTyped, BrandFontsInnerToJSON } from './BrandFontsInner';
import type { BrandPalettesInner } from './BrandPalettesInner';
import { BrandPalettesInnerFromJSON, BrandPalettesInnerFromJSONTyped, BrandPalettesInnerToJSON } from './BrandPalettesInner';

/**
 *
 * @export
 * @interface BrandWithAssets
 */
export interface BrandWithAssets {
  /**
   *
   * @type {string}
   * @memberof BrandWithAssets
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BrandWithAssets
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BrandWithAssets
   */
  user_id: string;
  /**
   *
   * @type {string}
   * @memberof BrandWithAssets
   */
  organization_id: string;
  /**
   *
   * @type {Date}
   * @memberof BrandWithAssets
   */
  created_at: Date;
  /**
   *
   * @type {Date}
   * @memberof BrandWithAssets
   */
  updated_at: Date;
  /**
   *
   * @type {Array<Asset>}
   * @memberof BrandWithAssets
   */
  logo_assets: Array<Asset>;
  /**
   *
   * @type {Array<Asset>}
   * @memberof BrandWithAssets
   */
  photo_assets: Array<Asset>;
  /**
   *
   * @type {Array<Asset>}
   * @memberof BrandWithAssets
   */
  icon_assets: Array<Asset>;
  /**
   *
   * @type {Array<Asset>}
   * @memberof BrandWithAssets
   */
  graphic_assets: Array<Asset>;
  /**
   *
   * @type {Array<BrandPalettesInner>}
   * @memberof BrandWithAssets
   */
  palettes: Array<BrandPalettesInner>;
  /**
   *
   * @type {Array<BrandFontsInner>}
   * @memberof BrandWithAssets
   */
  fonts: Array<BrandFontsInner>;
}

/**
 * Check if a given object implements the BrandWithAssets interface.
 */
export function instanceOfBrandWithAssets(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'user_id' in value;
  isInstance = isInstance && 'organization_id' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'updated_at' in value;
  isInstance = isInstance && 'logo_assets' in value;
  isInstance = isInstance && 'photo_assets' in value;
  isInstance = isInstance && 'icon_assets' in value;
  isInstance = isInstance && 'graphic_assets' in value;
  isInstance = isInstance && 'palettes' in value;
  isInstance = isInstance && 'fonts' in value;

  return isInstance;
}

export function BrandWithAssetsFromJSON(json: any): BrandWithAssets {
  return BrandWithAssetsFromJSONTyped(json, false);
}

export function BrandWithAssetsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandWithAssets {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    user_id: json['user_id'],
    organization_id: json['organization_id'],
    created_at: new Date(json['created_at']),
    updated_at: new Date(json['updated_at']),
    logo_assets: (json['logo_assets'] as Array<any>).map(AssetFromJSON),
    photo_assets: (json['photo_assets'] as Array<any>).map(AssetFromJSON),
    icon_assets: (json['icon_assets'] as Array<any>).map(AssetFromJSON),
    graphic_assets: (json['graphic_assets'] as Array<any>).map(AssetFromJSON),
    palettes: (json['palettes'] as Array<any>).map(BrandPalettesInnerFromJSON),
    fonts: (json['fonts'] as Array<any>).map(BrandFontsInnerFromJSON),
  };
}

export function BrandWithAssetsToJSON(value?: BrandWithAssets | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    user_id: value.user_id,
    organization_id: value.organization_id,
    created_at: value.created_at.toISOString(),
    updated_at: value.updated_at.toISOString(),
    logo_assets: (value.logo_assets as Array<any>).map(AssetToJSON),
    photo_assets: (value.photo_assets as Array<any>).map(AssetToJSON),
    icon_assets: (value.icon_assets as Array<any>).map(AssetToJSON),
    graphic_assets: (value.graphic_assets as Array<any>).map(AssetToJSON),
    palettes: (value.palettes as Array<any>).map(BrandPalettesInnerToJSON),
    fonts: (value.fonts as Array<any>).map(BrandFontsInnerToJSON),
  };
}
