import { UserSettings } from '../../generated/app_server_sdk';
type LabId =
  | 'pedestals'
  | 'text-to-image'
  | 'scene-swap'
  | 'restyle-image'
  | 'home-staging'
  | 'text-to-graphic-design'
  | 'text-to-subject'
  | 'blend-two-images'
  | 'lora'
  | 'virtual-photoshoot'
  | 'text-extract';

interface LabListEntry {
  // added an id field because of the lookup in AIDesignSection.tsx;
  // I changed the label of the labs field after recording the demo vid
  // and broke the lookup; an unchanging id field would have prevented that
  id: LabId;

  name: string;
  featureFlag?: keyof UserSettings;
  defaultMediaUrl: string;
  hoverMediaUrl: string;
  icon: string;
  description: string;
  navigateUrl: string;
  flag?: keyof UserSettings;
}

function labEntry(id: LabId) {
  return LABS_LIST.find((lab) => lab.id === id);
}

export function labLabel(id: LabId, label: keyof LabListEntry) {
  const lab = labEntry(id);
  return String(lab?.[label]);
}

const LABS_LIST: LabListEntry[] = [
  {
    id: 'pedestals',
    name: 'AI Studio Packshot',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1733140499/app-materials/homepage-cards/packshot-pro_uby7bw.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1733140508/app-materials/homepage-cards/packshot-pro-hover_wecf6o.png',
    icon: 'game-icons:flat-platform',
    description: 'Effortlessly transform your product photos into professional studio-quality packshots with AI-powered enhancements',
    navigateUrl: '/labs/pedestals',
  },
  {
    id: 'text-to-image',
    name: 'AI Image Creator',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759255/txt-2-img-example-2_f5y9wq.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759255/txt-2-img-example-1_xwoqr5.jpg',
    icon: 'ph:pencil-fill',
    description: 'Instantly generate high-quality images from text descriptions, bringing your ideas to life with AI-powered creativity',
    navigateUrl: '/labs/text-to-image',
  },
  {
    id: 'scene-swap',
    name: 'AI Background Replacer',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730363300/lab-image_qmztmi.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730363258/inpaint_11_gfydsx.jpg',
    icon: 'mdi:brush-variant',
    description: 'Effortlessly transform your image by swapping the background with an AI-generated scene tailored to your vision',
    navigateUrl: '/labs/inpaint',
  },
  {
    id: 'restyle-image',
    name: 'Image Restyler',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730976808/app-materials/labs/samples/coffee_r7dkxv.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1730976801/app-materials/labs/samples/restyle_a83lrw.jpg',
    icon: 'material-symbols:style',
    description: 'Reimagine your images by preserving their structure while transforming them into a new artistic style with AI-driven creativity',
    navigateUrl: '/labs/restyle-image',
  },
  {
    id: 'home-staging',
    name: 'Realtor Room Decoration',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724080616/app-materials/homepage-cards/stage-your-place_pgtund.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724085434/app-materials/homepage-cards/stage-your-place-hover_tuaxf1.png',
    icon: 'material-symbols:scene',
    description: 'Furnishes and decorates empty rooms for real estate and interior design',
    navigateUrl: '/labs/home-staging',
  },
  {
    id: 'text-to-graphic-design',
    name: 'AI Design Sketcher',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724758051/app-materials/homepage-cards/txt-2-graphics-example2_ps8pda.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724758050/app-materials/homepage-cards/txt-2-graphics-example1_uxxgpx.jpg',
    icon: 'uil:window-grid',
    description: 'Quickly generate rough graphic design drafts from simple text prompts, helping you visualize ideas effortlessly',
    navigateUrl: '/labs/text-to-graphic-design',
  },
  {
    id: 'text-to-subject',
    name: 'AI Prop Generator',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724757350/app-materials/homepage-cards/text2props-example-1_rldtqs.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1724759049/text2props-example-2_ay4h4q.jpg',
    icon: 'material-symbols:emoji-objects-outline',
    description: 'Generates a transparent PNG of an object based on a text description',
    navigateUrl: '/labs/text-to-subject',
  },

  {
    id: 'blend-two-images',
    name: 'Image Blender',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1727608160/bed_u5n1dk.jpg',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1727608250/bed-pink_pein6k.jpg',
    icon: 'icon-park-outline:merge',
    description: 'Blends the content of one image with the style of another.',
    navigateUrl: '/labs/blend-two-images',
  },

  {
    id: 'lora',
    name: 'AI Model Trainer',
    featureFlag: 'lora_training_and_usage',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1743506328/app-materials/labs/ai-model-lab_ec0etl.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/w_600/v1743506329/app-materials/labs/ai-model-hover_ssnald.png',
    icon: 'oui:training',
    description: 'Teach the AI new concepts and bring them to life',
    navigateUrl: '/labs/lora',
    flag: 'lora_training_and_usage',
  },

  {
    id: 'virtual-photoshoot',
    name: 'Virtual Photoshoot',

    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/v1739793149/app-materials/homepage-cards/virtual-photoshoot_k86ysm.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/v1739793148/app-materials/homepage-cards/virtual-photoshoot-hover_wfyl22.png',

    icon: 'material-symbols-light:photo-camera-rounded',
    description: 'Generate professional product photoshoots in a virtual environment.',
    navigateUrl: '/labs/virtual-photoshoot',
  },
  {
    id: 'text-extract',
    name: 'Text Extract',
    defaultMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/v1739793149/app-materials/homepage-cards/virtual-photoshoot_k86ysm.png',
    hoverMediaUrl: 'https://res.cloudinary.com/ft-bounty/image/upload/v1739793148/app-materials/homepage-cards/virtual-photoshoot-hover_wfyl22.png',
    icon: 'material-symbols-light:text-ad',
    description: 'Edit text on any image - replace, remove, or add new text.',
    navigateUrl: '/labs/text-extract',
  },
];
export default LABS_LIST;
